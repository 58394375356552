import { useStore } from "../../utils/store";

import s from "./index.module.scss";

export default function LoadingModal() {
  const [store] = useStore();

  return (
    <div
      className={`${store.isLoading ? s.loading : ""} ${
        s["loading-modal-container"]
      }`}
    >
      <div className={`${s["lds-spinner"]} `}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
