export const getCarsOptions = ({ dispatch, navigate }) => {
  return {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: async res => {
      dispatch({ type: "updateCars", payload: res.data });
    },
    onError: error => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    }
  };
};
