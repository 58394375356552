import React from "react";
import { useParams } from "react-router-dom";

import "./index.scss";

export default function NavbarLink() {
  const { link } = useParams();

  const imageTutorialMobile = () => {
    switch (link) {
      case "about":
        return (
          <img
            className="tutorial"
            src={require("../../assets/aboutus320.png")}
            alt="about us"
          />
        );
      case "contact":
        return (
          <img
            className="tutorial"
            src={require("../../assets/contactus320.png")}
            alt="about us"
          />
        );
      default:
        return (
          <img
            className="tutorial"
            src={require("../../assets/products320.png")}
            alt="contact"
          />
        );
    }
  };

  const imageTutorialDesktop = () => {
    switch (link) {
      case "about":
        return (
          <img
            className="tutorial"
            src={require("../../assets/aboutus1300.png")}
            alt="about us"
          />
        );
      case "contact":
        return (
          <img
            className="tutorial"
            src={require("../../assets/contactus1300.png")}
            alt="about us"
          />
        );
      default:
        return (
          <img
            className="tutorial"
            src={require("../../assets/products1300.png")}
            alt="contact"
          />
        );
    }
  };

  return (
    <div className="link-wrapper">
      {window.screen.width >= 1366
        ? imageTutorialDesktop()
        : imageTutorialMobile()}
    </div>
  );
}
