import { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../utils/store";
import { logoutRequest } from "../../requests/logout-request";

import Sidebar from "./Account-Sidebar";
import AccountBottomMenu from "./Account-BottomMenu";

import { ReactComponent as HeaderBar } from "../../svgs/label-bar.svg";

import { default as s } from "./index.module.scss";
import { getCurrentUser } from "../../requests/currenct-user-request";

export default function Account() {
  const navigate = useNavigate();
  const [itemActive, setItemActive] = useState("garage");
  const [{ user }, dispatch] = useStore();

  const { mutate: getCurrentUserMutation } = useMutation(getCurrentUser, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: res => {
      dispatch({ type: "updateUser", payload: res.data });
    },
    onError: error => {
      dispatch({ type: "onError", payload: error });
      navigate("/response", { replace: true });
    }
  });

  const { mutate: mutateLogout } = useMutation(logoutRequest, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
      dispatch({ type: "updateUser", payload: undefined });
      dispatch({ type: "updateHistory", payload: [] });
      dispatch({ type: "updateCars", payload: [] });
      dispatch({ type: "updateShopList", payload: [] });
      dispatch({ type: "setGlobalShop", payload: {} });
      dispatch({ type: "updateReviews", payload: [] });
    },
    onSuccess: res => {
      dispatch({
        type: "onSuccess",
        payload: { visible: true, message: res.message }
      });
      navigate("/response", { replace: true, state: { from: "logout" } });
    },
    onError: error => {
      dispatch({ type: "onError", payload: error });
      navigate("/response", { replace: true });
    }
  });

  useEffect(() => {
    if (user === undefined) {
      getCurrentUserMutation();
    }
    return () => {};
  }, [user, getCurrentUserMutation]);

  const handleTabNavigation = tab => {
    setItemActive(tab);
    tab === "garage" ? navigate("") : navigate(tab);
  };
  // todo: handle session expired and redirect to login page
  if (user) {
    return (
      <div className={s.accountContainer}>
        <div className={s["account-header"]}>
          <HeaderBar className={s["header-bar"]} />
          <label>
            <span>{user?.email ? user.email.split("@")[0] : "User"}</span>
            <span>[{user?.role}]</span>
          </label>
        </div>
        <Sidebar
          onLogout={mutateLogout}
          onHandleTabNavigation={tab => handleTabNavigation(tab)}
        />
        <AccountBottomMenu
          onLogout={mutateLogout}
          itemActive={itemActive}
          onHandleTabNavigation={tab => handleTabNavigation(tab)}
        />
        <div className={s["account-main-section"]}>
          <Outlet />
        </div>
      </div>
    );
  }
}
