import s from "./index.module.scss";

export default function AccountItem({
  desc,
  setValue,
  clearPrompt,
  show = true
} = {}) {
  return (
    <div className={`${s["account-item-container"]} ${show ? s.show : ""}`}>
      <label>{desc}</label>
      <input
        onChange={e => setValue(e.target.value)}
        onFocus={() => clearPrompt()}
        type={desc.toLowerCase().includes("hasło") ? "password" : "text"}
        spellCheck="false"
      />
    </div>
  );
}
