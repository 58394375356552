import { UserPersonalDataModel } from "../utils/modelHelper";
import { CustomError } from "./customError";

export const userUpdateRequest = formData => {
  return fetch(`/api/user/update/${formData.userID}`, {
    method: "POST",
    body: JSON.stringify(UserPersonalDataModel(formData.userForm)),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return res.json();
  });
};

export const userRevalidateRequest = () => {
  return fetch(`/api/user/user-revalidate`, {
    method: "POST"
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return res.json();
  });
};
