import { useState } from "react";

import { useStore } from "../../utils/store";

import { ReactComponent as Logout } from "../../svgs/logout.svg";
import { ReactComponent as Settings } from "../../svgs/settings.svg";
import { ReactComponent as Help } from "../../svgs/help.svg";
import { ReactComponent as Garage } from "../../svgs/garage.svg";
import { ReactComponent as User } from "../../svgs/account.svg";

import s from "./account-sidebar.module.scss";

export default function Account_Sidebar({ onHandleTabNavigation, onLogout }) {
  const [showSideMenu] = useState(true);
  const [showMenuItems] = useState(true);
  const [, dispatch] = useStore();

  return (
    <div className={s["account-sidebar"]}>
      <div
        className={`${s["account-sidebar-menu"]} ${showSideMenu ? s.menu : ""}`}
      >
        <div
          className={`${s["dropdown-link"]} ${showMenuItems ? s.items : ""}`}
          onClick={() => onHandleTabNavigation("user")}
        >
          <User />
          <span>KONTO</span>
        </div>
        <div
          className={`${s["dropdown-link"]} ${showMenuItems ? s.items : ""}`}
          onClick={() => onHandleTabNavigation("garage")}
        >
          <Garage />
          <span>GARAŻ</span>
        </div>
        <div
          className={`${s["dropdown-link"]} ${showMenuItems ? s.items : ""}`}
          onClick={() => onHandleTabNavigation("help")}
        >
          <Help />
          <span>POMOC</span>
        </div>
        <div
          className={`${s["dropdown-link"]} ${showMenuItems ? s.items : ""}`}
          onClick={() => onHandleTabNavigation("settings")}
        >
          <Settings />
          <span>USTAWIENIA</span>
        </div>
        <div
          className={`${s["dropdown-link"]} ${showMenuItems ? s.items : ""}`}
          onClick={() => {
            dispatch({ type: "onLoading", payload: "true" });
            onLogout();
          }}
        >
          <Logout />
          <span>WYLOGUJ</span>
        </div>
      </div>
    </div>
  );
}
