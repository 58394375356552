import { CustomError } from "./customError";

export const getAllUsersRequest = () => {
  return fetch(`/api/user/getUsers`, {
    method: "POST"
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const removeUserRequest = formData => {
  return fetch(`/api/user/remove`, {
    method: "DELETE",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};
