import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../utils/store";
import { getCarsRequest } from "../../requests/car-request";
import { ReactComponent as Call } from "../../svgs/call.svg";
import { ReactComponent as Email } from "../../svgs/email.svg";
import { ReactComponent as Service } from "../../svgs/handyman.svg";
// import { ReactComponent as Chat } from '../../svgs/chat.svg'
import { ReactComponent as Love } from "../../svgs/love.svg";

import "./radialmenu.scss";

export default function RadialMenu() {
  const navigate = useNavigate();
  const [{ showPieMenu, globalShop, shops }, dispatch] = useStore();
  const [displayInfo, setDisplayInfo] = useState("");
  const [collapseMenuItems, setCollapseMenuItems] = useState("");

  const { mutate } = useMutation(getCarsRequest, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
      dispatch({ type: "pieMenuToggle", payload: false });
    },
    onSuccess: async (res) => {
      dispatch({ type: "updateCars", payload: res.data });
      dispatch({ type: "pickACarModalToggle", payload: true });
    },
    onError: (error) => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  });

  const handleModalToggle = () => {
    console.log("fired");
    dispatch({ type: "pieMenuToggle", payload: false });
    dispatch({ type: "setGlobalShop", payload: {} });
    shops[shops.indexOf(globalShop)].picked = false;
  };

  const handlePickACarModalOpen = () => {
    dispatch({ type: "pieMenuToggle", payload: false });
    mutate();
  };

  const handleContactDisplay = (e, target) => {
    if (collapseMenuItems) {
      setCollapseMenuItems("");
      setDisplayInfo("");
    } else {
      setCollapseMenuItems("collapse");
      setDisplayInfo(target);
    }
    e.stopPropagation();
  };

  return (
    <div className={`radial-menu ${showPieMenu ? "" : "hidden"}`}>
      <div className={`${displayInfo} info`}>
        <span>
          <a
            className="phoneLink"
            href={`tel:${
              displayInfo === "phone" ? globalShop.phone : globalShop.email
            }`}
          >
            {displayInfo === "phone" ? globalShop.phone : globalShop.email}
          </a>
        </span>
        <button
          onClick={(e) => {
            handleContactDisplay(e, undefined);
          }}
        >
          Wstecz
        </button>
      </div>
      <div
        onClick={handleModalToggle}
        id="menu-toggler"
        className={`menu-toggler ${collapseMenuItems}`}
      ></div>
      <label htmlFor="menu-toggler" className={`${collapseMenuItems}`} />
      <ul className={`${showPieMenu ? "swirl" : ""}`}>
        <li className={`${collapseMenuItems ? "collapse" : ""} menu-item`}>
          <div
            onClick={(e) => {
              handleContactDisplay(e, "phone");
            }}
          >
            <Call />
            <span className="radial-menu-item-desc">tel</span>
          </div>
        </li>
        <li className={`${collapseMenuItems ? "collapse" : ""} menu-item`}>
          <div
            onClick={(e) => {
              handleContactDisplay(e, "email");
            }}
          >
            <Email />
            <span className="radial-menu-item-desc">email</span>
          </div>
        </li>
        <li className={`${collapseMenuItems} menu-item `}>
          <div onClick={() => handlePickACarModalOpen()}>
            <Service />
            <span className="radial-menu-item-desc">napraw</span>
          </div>
        </li>
        {/* <li className="menu-item">
          <div><Chat/><span className="radial-menu-item-desc">chat</span></div>
        </li> */}
        <li className={`${collapseMenuItems} menu-item `}>
          <div
            onClick={() => {
              navigate("/account/garage/myshop");
              dispatch({ type: "pieMenuToggle", payload: false });
            }}
          >
            <Love />
            <span className="radial-menu-item-desc">o nas</span>
          </div>
        </li>
      </ul>
    </div>
  );
}
