import React from "react";

export default function Rodo2() {
  return (
    <p>
      Przetwarzanie danych osobowych Administrator danych Łukasz Kozłowski
      działający pod firmą: Łukasz Kozłowski, NIP 7412166392, adres: Odmy 4
      14-120 Dąbrówno, strona internetowa: www.motohistoria.pl Cele
      przetwarzania Świadczenie Usługi udostępnienia Aplikacji Motohistoria
      Kierowanie informacji handlowych Utrzymywanie stałego kontaktu Podstawy
      prawne przetwarzania Umowa o świadczenie usług drogą elektroniczną Zgoda
      Klienta Uzasadniony interes Stron Prawa związane z przetwarzaniem danych
      prawo wycofania zgody na przetwarzanie danych prawo dostępu do danych
      osobowych oraz prawo żądania ich sprostowania, ich usunięcia lub
      ograniczenia ich przetwarzania inne prawa określone w informacji
      szczegółowej Szczegółowe informacje dotyczące przetwarzania danych
      Użytkownika 1. Administrator danych osobowych Administratorem danych
      osobowych Użytkownika jest Łukasz Kozłowski działający pod firmą: Łukasz
      Kozłowski, NIP: 7412166392, adres: Odmy 4 14-120, strona internetowa:
      www.motohistoria.pl (dalej: Usługodawca). Użytkownik może się kontaktować
      w następujący sposób: - listownie na adres: Odmy 4 14-120 Dąbrówno; -
      przez e-mail: admin@motohistoria.pl; - telefonicznie: 572 208 679. 2. Cele
      przetwarzania oraz podstawa prawna przetwarzania Usługodawca będzie
      przetwarzać dane osobowe Użytkownika, aby: - umożliwić świadczenie Usługi
      udostępnienia Aplikacji Motohistoria, w celu jej rozwoju, poprawy
      funkcjonalności; - kierować informację handlową na wskazane adresy
      Użytkownika, w tym adres poczty elektronicznej oraz na telefon; -
      utrzymywać stały kontakt z Użytkownikiem. 3. Przekazywanie danych
      osobowych podmiotom trzecim Dane osobowe Użytkownika w zakresie niezbędnym
      do zrealizowania płatności za świadczoną Usługę udostępnienia Aplikacji
      Motohistoria przez Usługodawcę mogą być przekazywane operatorom systemów
      płatności elektronicznej, w szczególności, bankom, operatorom kart
      płatniczych. Dla prawidłowej realizacji Usługi udostępnienia Aplikacji
      Motohistoria konieczne jest wzajemne przekazanie danych pomiędzy
      Użytkownikiem – Klientem, a Użytkownikiem – Serwisem. Usługodawca może
      powierzyć przetwarzanie danych osobowych innym podmiotom przetwarzającym w
      zakresie niezbędnym do realizacji Usługi udostępnienia Aplikacji
      Motohistoria. 4. Okres przechowywania danych osobowych Dane osobowe
      przechowywane są przez cały okres świadczenia Usługi udostępnienia
      Aplikacji Motohistoria, a po zakończeniu świadczenia tej usługi przez
      okres wymagany przez obowiązujące przepisy, w szczególności rachunkowe,
      podatkowe, cywilne w zakresie przedawnienia roszczeń. 5. Prawa Użytkownika
      związane z przetwarzaniem danych osobowych i podejmowaniem
      zautomatyzowanych decyzji Użytkownikowi przysługują następujące prawa
      związane z przetwarzaniem danych osobowych: a. prawo wycofania zgody na
      przetwarzanie danych, b. prawo dostępu do swoich danych osobowych, c.
      prawo żądania sprostowania swoich danych osobowych, d. prawo żądania
      usunięcia swoich danych osobowych, e. prawo żądania ograniczenia
      przetwarzania swoich danych osobowych, f. prawo wyrażenia sprzeciwu wobec
      przetwarzania swoich danych ze względu na szczególną sytuację Użytkownika,
      g. prawo do przenoszenia swoich danych osobowych, tj. prawo otrzymania od
      Usługodawcy swoich danych osobowych, w ustrukturyzowanym, powszechnie
      używanym formacie informatycznym nadającym się do odczytu maszynowego.
      Użytkownik może przesłać te dane innemu administratorowi danych lub
      zażądać, aby Usługodawca przesłał te dane do innego administratora.
      Jednakże Usługodawca zrobi to tylko jeśli takie przesłanie jest
      technicznie możliwe. Skorzystanie z prawa do wycofania zgody na
      przetwarzanie danych osobowych, usunięcia danych osobowych lub
      ograniczenia przetwarzania danych osobowych skutkuje zaprzestaniem
      świadczenia Usługi udostępnienia Aplikacji Motohistoria. Aby skorzystać z
      powyższych praw, należy skontaktować się z Usługodawcą, dane kontaktowe w
      punkcie 1. powyżej). Prawo wniesienia skargi do organu Użytkownikowi
      przysługuje prawo wniesienia skargi do organu nadzorczego zajmującego się
      ochroną danych osobowych, tj. Prezesa Urzędu Ochrony Danych Osobowych.
    </p>
  );
}
