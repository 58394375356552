import React from "react";
import { Navigate } from "react-router-dom";
import { useStore } from "../utils/store";

export default function Guard({ component, userRole }) {
  const [
    {
      user: { role }
    }
  ] = useStore();
  return userRole.includes(role) ? component : <Navigate to="/" />;
}
