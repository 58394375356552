export const REGEX = {
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?!.*\s).{8,30}$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

export const validateForm = (
  { email, password, confirmPassword, shop, reset = false } = {},
  flag,
  setInvalidator,
  setReadyToAPI,
  dispatch = null
) => {
  const showTerms = () => {
    setReadyToAPI(true);
    dispatch && dispatch({ type: "toggleConsentForm", payload: true });
  };

  const emailFailedTest = !REGEX.EMAIL.test(email);
  const passwordFailedTest = !validatePassword(password);
  if (flag === "register")
    !emailFailedTest &&
      !passwordFailedTest &&
      password === confirmPassword &&
      showTerms();
  else if (flag === "login")
    !emailFailedTest && !passwordFailedTest && showTerms();
  else !emailFailedTest && showTerms();

  setInvalidator((prev) => ({
    ...prev,
    shop: !(shop?.length > 3),
    email: emailFailedTest,
    password: passwordFailedTest,
    confirmPassword: passwordFailedTest
      ? false
      : password === confirmPassword
      ? false
      : true,
  }));
};

export function validatePassword(password) {
  return REGEX.PASSWORD.test(password);
}
