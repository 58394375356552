import { CustomError } from "./customError";

export const carAddRequest = formData => {
  return fetch(`/api/car/add`, {
    method: "PUT",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const getCarsRequest = () => {
  return fetch(`/api/car/getCars`, {
    method: "POST",
    body: "",
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const sendCarToService = formData => {
  return fetch(`/api/service/add`, {
    method: "PUT",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};
