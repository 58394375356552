import s from "../app.module.scss";
import doc1 from "../assets/Informacja_do_uzytkownik_Motohistorii.pdf";
import doc2 from "../assets/Oswiadczenia_zgody_uzytkownik_Motohistorii.pdf";
import doc3 from "../assets/Regulamin_Usługi_Motohistoria.pdf";
import { useLocation } from "react-router-dom";
import { useStore } from "../utils/store";

export const Footer = () => {
  const location = useLocation();
  const [{ user }] = useStore();

  const isRegister = location.pathname === "/register";

  if (isRegister) {
    return null;
  }
  if (user) {
    return null;
  }
  return (
    <div className={s.footer}>
      <ul className={s.footer_container}>
        <li>
          <a className={s.nav_social_link} href={doc1} rel="noreferrer"  target="_blank">
            Informacja
          </a>
        </li>
        <li>
          <a className={s.nav_social_link} href={doc2} rel="noreferrer" target="_blank">
            Oświadczenie zgody uzytkownika
          </a>
        </li>
        <li>
          <a className={s.nav_social_link} href={doc3} rel="noreferrer" target="_blank">
            Regulamin
          </a>
        </li>
      </ul>
    </div>
  );
};
