import { useStore } from "../../../utils/store";

import TutorialMechanicMobile from "../../../assets/mechanic-tutorial-mobile.png";
import TutorialMechanicDesktop from "../../../assets/mechanic-tutorial-desktop.png";
import TutorialUserMobile from "../../../assets/user-tutorial-mobile.png";
import TutorialUserDesktop from "../../../assets/user-tutorial-desktop.png";

export default function AccountHelp() {
  const [
    {
      user: { role }
    }
  ] = useStore();
  return (
    <div
      style={{
        height: "calc(100vh - 160px)",
        width: "100vw",
        overflowY: "scroll",
        display: "grid",
        justifyContent: "center",
        alignItems: "start"
      }}
    >
      {role === "USER" ? (
        <img
          style={{
            maxWidth: " 1366px",
            height: "auto",
            position: "relative",
            display: "grid",
            top: "0px"
          }}
          src={
            window.screen.width >= 1366
              ? TutorialUserDesktop
              : TutorialUserMobile
          }
          alt="how to download and install app"
        />
      ) : (
        <img
          style={{
            maxWidth: " 1366px",
            height: "auto",
            position: "relative",
            display: "grid",
            top: "0px"
          }}
          src={
            window.screen.width >= 1366
              ? TutorialMechanicDesktop
              : TutorialMechanicMobile
          }
          alt="how to download and install app"
        />
      )}
    </div>
  );
}
