import { CustomError } from "./customError";

export const addReview = formData => {
  return fetch(`/api/review/add`, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const updateReview = formData => {
  return fetch(`/api/review/update`, {
    method: "PUT",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const getReviews = serviceID => {
  return fetch(`/api/review/reviews/${serviceID}`, {
    method: "POST"
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};
