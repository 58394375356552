import { useStore } from "../../utils/store";

import "./index.scss";

export default function SuccessModal() {
  const [{ success }] = useStore();

  return (
    <div
      className={`success-modal-container ${
        success.visible ? "showCheckmark" : ""
      }`}
    >
      <div className="svg-box">
        <svg className="circular success-green-stroke">
          <circle
            className="path"
            cx="75"
            cy="75"
            r="50"
            fill="none"
            strokeWidth="5"
            strokeMiterlimit="10"
          />
        </svg>
        <svg className="success-green-stroke checkmark">
          <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
            <path
              fill="none"
              d="M616.306,283.025L634.087,300.805L673.361,261.53"
            />
          </g>
        </svg>
      </div>
      {success.message ? (
        <label className="succes-modal-message">{success.message}</label>
      ) : (
        undefined
      )}
    </div>
  );
}
