import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

import { useStore } from "../../utils/store";

export default function Checkboxes({ index, children }) {
  const [checkedState, setCheckedState] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [{ acceptTerms }, dispatch] = useStore();

  useEffect(() => {
    const newTerms = [...acceptTerms];
    newTerms[index] = checkedState;
    dispatch({ type: "acceptTerms", payload: newTerms });
// eslint-disable-next-line
  }, [checkedState]);

  const handleCheckboxAction = () => {
    setCheckedState(!checkedState);
  };

  return (
    <div className="consent-form" onClick={() => handleCheckboxAction()}>
      <Checkbox {...label} checked={checkedState} />
      <span
        className="consent-form-label"
        style={{ font: "300 .8rem Titillium Web", lineHeight: "15px" }}
      >
        {children}
      </span>
    </div>
  );
}
