import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../../utils/store";

import { CAR_BRANDS } from "../../../../constants/car-brands";
import FUEL_ARRAY from "../../../../constants/fuel";
import YEARS_ARRAY from "../../../../constants/car-year";
import { UserCarModel } from "../../../../utils/modelHelper";
import { carAddRequest } from "../../../../requests/car-request";

import Select from "../../../Select";

import "./index.scss";

export default function AddCar() {
  const [def] = useState(UserCarModel());
  const [car, setCar] = useState(def);
  const [modelList, setModelList] = useState([]);
  const [genList, setGenList] = useState([]);
  const [saveButtonText] = useState("dodaj");
  const navigate = useNavigate();
  const [, dispatch] = useStore();

  const { mutate } = useMutation(carAddRequest, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: async (res) => {
      //To do: add success message
      // dispatch({type: "onSuccess", payload: { message: res.message, visible: true}});
      navigate("/account/garage/mycars");
    },
    onError: (error) => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  });

  const handleCarModelSelection = (brand) => {
    setCar({ ...car, brand, gen: def.gen, model: def.model });
    setGenList([]);
    import(`../../../../constants/car-models/${brand}.js`)
      .then((module) => {
        setModelList(module.default);
      })
      .catch(() => setModelList([]));
  };

  const handleCarGenSelection = (model) => {
    setCar({ ...car, model, gen: def.gen });
    import(
      `../../../../constants/car-gens/${car.brand
        .toLowerCase()
        .replace(/\s+/g, "")}/${model.replace(/\s+/g, "").toUpperCase()}.js`
    )
      .then((module) => {
        setGenList(module.default);
      })
      .catch(() => {
        setGenList([]);
      });
  };

  return (
    <div className="add-car-container">
      <Select
        setParentState={(brand) => handleCarModelSelection(brand)}
        defaultTitle={car.brand ? car.brand : "marka pojazdu"}
        list={CAR_BRANDS}
      />
      <Select
        setParentState={(model) => handleCarGenSelection(model)}
        defaultTitle={car.model ? car.model : "model pojazdu"}
        list={modelList}
        disabled={!modelList.length}
      />
      <Select
        setParentState={(gen) => setCar({ ...car, gen: gen })}
        disabled={!genList.length}
        defaultTitle={car.gen ? car.gen : "generacja"}
        list={genList}
      />
      <Select
        setParentState={(year) => setCar({ ...car, year })}
        defaultTitle={car.year ? car.year : "rok produkcji"}
        list={YEARS_ARRAY}
      />
      <Select
        setParentState={(fuel) => setCar({ ...car, fuel })}
        defaultTitle={car.fuel ? car.fuel : "rodzaj paliwa"}
        list={FUEL_ARRAY}
      />
      <input
        onChange={(e) => setCar({ ...car, registration: e.target.value })}
        className="select registration"
        placeholder={
          car.registration ? car.registration : "numer rejestracyjny"
        }
        style={{ textTransform: "uppercase" }}
        maxLength="8"
      />
      <input
        onChange={(e) => setCar({ ...car, vin: e.target.value })}
        className="select registration"
        placeholder={car.vin ? car.vin : "VIN"}
        style={{ textTransform: "uppercase" }}
        maxLength="17"
      />
      <button onClick={() => mutate(car)}>{saveButtonText}</button>
    </div>
  );
}
