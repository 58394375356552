import { useNavigate } from "react-router-dom";

import { ReactComponent as CarKey } from "../../svgs/car-key.svg";

import s from "./index.module.scss";

export default function Home() {
  const navigate = useNavigate();

  return (
    <div className={s.home_container}>
      <div className={s.opis_logowanie}>
        <span className={s.opis_logowanie}>
          <span>Najlepsze</span> warsztaty To pewna{" "}
          <span>Historia. Stwórzmy</span> ją razem...
        </span>
        <div className={s.klient}>
          <div
            className={s.button_home_role}
            onClick={() => navigate("/login")}
          >
            <span>Zaloguj</span>
            <CarKey />
          </div>
          <div className={s.register_prompt}>
            <p>Nie masz jeszcze konta?</p>
            <p onClick={() => navigate("/register")}>Zarejestruj się</p>
          </div>
        </div>
      </div>
    </div>
  );
}
