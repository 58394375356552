import React from "react";

import { useStore } from "../../../../../utils/store";
import Rating from "../../../../Rating";
import {
  GetMonth,
  DayOfMonth,
  GetYear
} from "../../../../../utils/dateConverter";

import "./index.scss";

export default function Reviews() {
  const [{ reviews }] = useStore();

  return reviews.length ? (
    <div className="review-wrapper">
      {reviews.map((review, index) => (
        <div key={index}>
          <span className="review-car-name">{review.authorCar}</span>
          <div className="content">
            <Rating stars={review.score} readonly={true} size="medium" />
            <span>{review.content}</span>
            <span className="timestamp">{`${DayOfMonth(
              review.createdAt
            )} ${GetMonth(review.createdAt)} ${GetYear(
              review.createdAt
            )}`}</span>
            <span className="author-name">
              {review.authorName.length < 2
                ? "Brak danych".toUpperCase()
                : review.authorName.toUpperCase()}
            </span>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="review-wrapper-no-reviews">
      <span>brak opinii</span>
    </div>
  );
}
