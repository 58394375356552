import { createContext, useReducer, useContext } from "react";

import GlobalReducer, { initialState } from "./globalReducer";

const Store = createContext();

export const useStore = () => useContext(Store);

export const StoreProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer(GlobalReducer, initialState);

  return (
    <Store.Provider value={[globalState, dispatch]}>{children}</Store.Provider>
  );
};
