export const getServicesOptions = ({
  dispatch,
  navigate,
  setServiceHistoryFetched
}) => {
  return {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: (res, variables) => {
      dispatch({ type: "updateHistory", payload: res.data });
      if (variables.to) {
        navigate(variables.to, { replace: variables.replace || false });
      }
      setServiceHistoryFetched && setServiceHistoryFetched(true);
    },
    onError: error => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    }
  };
};
