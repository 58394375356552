import { useEffect } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useStore } from "../../utils/store";

import ReplyIcon from "@mui/icons-material/Reply";

import "./index.scss";

export default function Response() {
  const [{ error, success, user }, dispatch] = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: "onSuccess", payload: { visible: false } });
      if (location.state?.from === "logout") navigate("/", { replace: true });
    }, 2000);
    if (error)
      return () => {
        dispatch({ type: "onErrorReset" });
      };
    // eslint-disable-next-line
  }, []);

  if (error)
    return (
      <div className="response-container">
        <div className="response-header"></div>
        <div className="response-main-section"></div>
        <button
          className="response-back-home-button"
          onClick={() =>
            error.name === "TokenError" || !user
              ? navigate("/login", { replace: true })
              : navigate(-1, { replace: true })
          }
        >
          <ReplyIcon />
        </button>
      </div>
    );
  else if (success.visible) {
    return (
      <div className="response-container">
        <div className="response-header"></div>
        <div className="response-main-section"></div>
      </div>
    );
  } else {
    return <Navigate to="/" replace="true" />;
  }
}
