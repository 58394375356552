import React, { useEffect } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

const labels = {
  0.5: "Tragiczny!",
  1: "Beznadziejny",
  1.5: "Do niczego",
  2: "Niemożliwy",
  2.5: "Słaby",
  3: "Przeciętny",
  3.5: "Może być",
  4: "Dobry",
  4.5: "Bardzo dobry",
  5: "Wspaniały!"
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function HoverRating({
  className,
  score,
  onSetScore,
  readonly
}) {
  const [value, setValue] = React.useState(score);
  const [hover, setHover] = React.useState(-1);

  useEffect(() => {
    setValue(score);
  }, [score]);

  return (
    <div className={className}>
      <Box
        sx={{
          width: 150,
          display: "block",
          alignItems: "center"
        }}
      >
        <Rating
          name="hover-feedback"
          readOnly={readonly}
          size="large"
          value={value}
          precision={1}
          getLabelText={getLabelText}
          onChange={(event, newValue) => {
            if (newValue) {
              setValue(newValue);
              onSetScore(newValue);
            }
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
        {value !== null && (
          <Box sx={{ display: "grid", textAlign: "center", mt: 1 }}>
            {labels[hover !== -1 ? hover : value]}
          </Box>
        )}
      </Box>
    </div>
  );
}
