import React from "react";
import { useStore } from "../../utils/store";
import Checkbox from "./Checkbox";

import "./index.scss";
import doc1 from "../../assets/Informacja_do_uzytkownik_Motohistorii.pdf";
import doc2 from "../../assets/Oswiadczenia_zgody_uzytkownik_Motohistorii.pdf";
import doc3 from "../../assets/Regulamin_Usługi_Motohistoria.pdf";

export default function Regulations() {
  const [{ consentFormDisplay, acceptTerms }, dispatch] = useStore();
  const handleCloseConsentForm = () => {
    dispatch({ type: "toggleConsentForm", payload: false });
  };

  return (
    <div
      className={`consent-form-wrapper  ${consentFormDisplay ? "show" : ""}`}
    >
      <div className="consent-form-actions-body">
        <Checkbox index={0}>
          Zapoznałem się z{" "}
          <a href={doc1} rel="noreferrer" target="_blank">
            informacją dla Użytkowników Aplikacji Motohistoria.pl
          </a>{" "}
          o przetwarzaniu danych osobowych.
        </Checkbox>
        <Checkbox index={1}>
          Wyrażam zgodę na{" "}
          <a href={doc2} rel="noreferrer" target="_blank">
            przetwarzanie danych osobowych
          </a>
          przez administratora danych osobowych.
        </Checkbox>
        <Checkbox index={2}>
          Akceptuję{" "}
          <a href={doc3} rel="noreferrer" target="_blank">
            regulamin Aplikacji Motohistoria.pl
          </a>
        </Checkbox>
      </div>
      <div
        className="consent-form-actions-wrapper-footer"
        onClick={handleCloseConsentForm}
      >
        <button>Rezygnuję</button>
        <button
          onClick={() =>
            dispatch({ type: "termsAccepted", payload: acceptTerms })
          }
        >
          Akceptuję
        </button>
      </div>
    </div>
  );
}
