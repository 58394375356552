import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../../../utils/store";
import { useMutation } from "@tanstack/react-query";
import { userUpdateRequest } from "../../../../requests/udpate-request";
import { getReviews } from "../../../../requests/review-request";

import Rating from "../../../Rating";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTube from "@mui/icons-material/YouTube";
import { ReactComponent as Call } from "../../../../svgs/call.svg";
import { ReactComponent as Address } from "../../../../svgs/address.svg";

import RhomboidLoader from "../../../RomboidLoader";
import "./index.scss";

export default function MyShop() {
  const [{ user, globalShop, reviews }, dispatch] = useStore();
  const [previewAccount] = useState(globalShop._id ? globalShop : user);
  const [timeoutRef, setTimeoutRef] = useState(undefined);
  const [showLoader, setShowLoader] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [imageSource, setImageSource] = useState(
    window.screen.width >= 1024
      ? require("../../../../assets/desktop-shop-card.png")
      : require("../../../../assets/mobile-shop-card.png")
  );
  const [specializations, setSpecializations] = useState(
    previewAccount.specializations?.length ? previewAccount.specializations : []
  );
  const navigate = useNavigate();

  const { mutate } = useMutation(userUpdateRequest, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      setTimeout(() => {
        dispatch({ type: "onLoading", payload: undefined });
      }, 950);
    },
    onSuccess: ({ data }) => {
      dispatch({ type: "updateUser", payload: data });
      setShowLoader([]);
    },
    onError: (error) => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  });

  const { mutate: fetchReviews } = useMutation(getReviews, {
    retry: false,
    onSuccess: ({ data }) => {
      dispatch({ type: "updateReviews", payload: data });
    },
    onError: (error) => {
      dispatch({ type: "updateReviews", payload: [] });
    },
  });

  useEffect(() => {
    fetchReviews(previewAccount._id);
    //eslint-disable-next-line
  }, []);

  const handleSaveService = (input, index) => {
    clearTimeout(timeoutRef);
    if (input) {
      const newSpecializations = specializations;
      specializations.splice(index, 1, input);
      setSpecializations(newSpecializations);
      setTimeoutRef(
        setTimeout(() => {
          mutate({ userForm: { specializations }, userID: user._id });
          setShowLoader((prev) => {
            const after = [...prev];
            after[index] = false;
            return after;
          });
        }, 2800)
      );
      setShowLoader((prev) => {
        const after = [...prev];
        after[index] = true;
        return after;
      });
    } else {
      setShowLoader((prev) => {
        const after = [...prev];
        after[index] = false;
        return after;
      });
    }
  };

  const handleAddSocialLink = (tab) => {
    setActiveTab(tab);
    if (activeTab === tab) setActiveTab(null);
    else setActiveTab(tab);
  };

  const handleAddPhoto = (e) => {
    const image = e.target.files[0];
    if (image) {
      const URL = window.URL || window.webkitURL;
      const source = URL.createObjectURL(image);
      setImageSource(source);
    }
  };

  return (
    <div>
      <div className="shop-title">
        {previewAccount.serviceName?.toUpperCase()}
      </div>
      <div className="shop-layout-grid">
        <div className="shop-main-photo-wrapper">
          <input
            disabled={globalShop._id ? true : false}
            className="service-photo-input"
            type="file"
            onChange={(e) => {
              handleAddPhoto(e);
            }}
            accept="image/*"
            capture
          />
          <div
            className={`photo-input-placeholer-container ${
              imageSource ? "image" : ""
            }`}
          >
            <img
              className={`shop-card-photo ${imageSource ? "" : "hidden"}`}
              src={imageSource}
              alt="shop card"
            />
            <div className={`dashed-box ${imageSource ? "hidden" : ""}`}>
              {/* <AddPhotoAlternateIcon/>
            <span>Dodaj zdjęcie</span> */}
            </div>
          </div>
          <div className="shop-links-bar">
            <div
              className={`${activeTab === "youtube" ? "active" : ""}`}
              onClick={() => handleAddSocialLink("youtube")}
            >
              <YouTube />
            </div>
            <div
              className={`${activeTab === "facebook" ? "active" : ""}`}
              onClick={() => handleAddSocialLink("facebook")}
            >
              <FacebookIcon />
            </div>
            <div
              className={`${activeTab === "twitter" ? "active" : ""}`}
              onClick={() => handleAddSocialLink("twitter")}
            >
              <TwitterIcon />
            </div>
            <div
              className={`${activeTab === "instagram" ? "active" : ""}`}
              onClick={() => handleAddSocialLink("instagram")}
            >
              <InstagramIcon />
            </div>
          </div>
        </div>
        <div className="shop-reviews-wrapper">
          <div className="star-count">
            <Rating
              readonly={true}
              stars={previewAccount.rating}
              size={"medium"}
            />
          </div>
          <span onClick={() => navigate("reviews")}>
            zobacz opinie ({reviews.length || "0"})
          </span>
          {/* <div className="reviews-bottom-bar">
            <span>promocje</span>
          </div> */}
        </div>
        <div className={`shop-contact ${activeTab ? "social-link" : ""}`}>
          <Call />

          <span>
            <a className="phoneLink" href={`tel:${previewAccount.phone}`}>
              {previewAccount.phone}
            </a>
          </span>
        </div>
        <div className={`shop-hours ${activeTab ? "social-link" : ""}`}>
          <Address />
          <span>
            {previewAccount.address.street} {previewAccount.address.streetNo}
          </span>
          <span>
            {previewAccount.address.postalCode} {previewAccount.address.city}
          </span>
        </div>
        <div
          className={`social-link-input-wrapper ${
            activeTab ? "social-link" : ""
          }`}
        >
          {activeTab === "youtube" ? (
            <YouTube />
          ) : activeTab === "facebook" ? (
            <FacebookIcon />
          ) : activeTab === "twitter" ? (
            <TwitterIcon />
          ) : activeTab === "instagram" ? (
            <InstagramIcon />
          ) : null}
          <input type="text" placeholder="wprowadź link" />
        </div>
        <div className="shop-address"></div>
        <div className="shop-service-range-wrapper">
          <div>
            <input
              disabled={globalShop._id ? true : false}
              defaultValue={specializations[0]}
              onChange={(e) => {
                handleSaveService(e.target.value, 0);
              }}
            />
            <RhomboidLoader display={showLoader[0]} />
          </div>
          <div>
            <input
              disabled={globalShop._id ? true : false}
              defaultValue={specializations[1]}
              onChange={(e) => {
                handleSaveService(e.target.value, 1);
              }}
            />
            <RhomboidLoader display={showLoader[1]} />
          </div>
          <div>
            <input
              disabled={globalShop._id ? true : false}
              defaultValue={specializations[2]}
              onChange={(e) => {
                handleSaveService(e.target.value, 2);
              }}
            />
            <RhomboidLoader display={showLoader[2]} />
          </div>
          <div>
            <input
              disabled={globalShop._id ? true : false}
              defaultValue={specializations[3]}
              onChange={(e) => {
                handleSaveService(e.target.value, 3);
              }}
            />
            <RhomboidLoader display={showLoader[3]} />
          </div>
          <div>
            <input
              disabled={globalShop._id ? true : false}
              defaultValue={specializations[4]}
              onChange={(e) => {
                handleSaveService(e.target.value, 4);
              }}
            />
            <RhomboidLoader display={showLoader[4]} />
          </div>
          <div>
            <input
              disabled={globalShop._id ? true : false}
              defaultValue={specializations[5]}
              onChange={(e) => {
                handleSaveService(e.target.value, 5);
              }}
            />
            <RhomboidLoader display={showLoader[5]} />
          </div>
          <div>
            <input
              disabled={globalShop._id ? true : false}
              defaultValue={specializations[6]}
              onChange={(e) => {
                handleSaveService(e.target.value, 6);
              }}
            />
            <RhomboidLoader display={showLoader[6]} />
          </div>
          <div>
            <input
              disabled={globalShop._id ? true : false}
              defaultValue={specializations[7]}
              onChange={(e) => {
                handleSaveService(e.target.value, 7);
              }}
            />
            <RhomboidLoader display={showLoader[7]} />
          </div>
          <div>
            <input
              disabled={globalShop._id ? true : false}
              defaultValue={specializations[8]}
              onChange={(e) => {
                handleSaveService(e.target.value, 8);
              }}
            />
            <RhomboidLoader display={showLoader[8]} />
          </div>
          <div>
            <input
              disabled={globalShop._id ? true : false}
              defaultValue={specializations[9]}
              onChange={(e) => {
                handleSaveService(e.target.value, 9);
              }}
            />
            <RhomboidLoader display={showLoader[9]} />
          </div>
        </div>
      </div>
    </div>
  );
}
