import React, { useState, useRef } from "react";

import s from "./index.module.scss";

export default function Select({
  list,
  defaultTitle,
  setParentState,
  disabled = false
}) {
  const [expand, setExpand] = useState(false);
  const optionList = useRef(null);

  const handleOptionListBehaviour = (optionTitle, behaviour, e = null) => {
    e?.stopPropagation();
    setExpand(behaviour);
    // eslint-disable-next-line
    behaviour ? optionList.current.focus() : false;
    // eslint-disable-next-line
    optionTitle ? setParentState(optionTitle) : false;
    optionList.current.scrollTop = 0;
  };

  return (
    <div className={`${s["select-container"]}`}>
      <div className={s.wrapper}>
        <div
          className={`${s["dummy-wrapper"]} ${expand ? s.expand : ""}`}
          onClick={e => handleOptionListBehaviour(undefined, true, e)}
        ></div>
        <ul className={s["default-option"]}>
          <li
            className={`${disabled ? s.disabled : ""}`}
            onClick={e => handleOptionListBehaviour(undefined, false, e)}
          >
            {defaultTitle.toUpperCase()}
          </li>
        </ul>
      </div>
      <div
        className={`${s["select-wrapper"]} ${expand ? s.expand : ""}`}
        onBlur={e => handleOptionListBehaviour(undefined, false, e)}
        tabIndex={0}
        ref={optionList}
      >
        {list &&
          list.map(item => (
            <ul key={item} className={s["select-option"]}>
              <li onClick={() => handleOptionListBehaviour(item, false)}>
                {item.toUpperCase()}
              </li>
            </ul>
          ))}
      </div>
    </div>
  );
}
