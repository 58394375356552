import React from "react";

export default function UserFormField({
  classes,
  placeholder,
  userKey,
  onUserFormSet,
  maxLength
}) {
  return (
    <div className={classes.join(" ")}>
      <input
        style={{
          textAlign:
            placeholder === "KOD" || userKey === "NUMER" ? "center" : "left"
        }}
        placeholder={placeholder}
        value={userKey}
        onChange={e => onUserFormSet(e.target.value)}
        maxLength={maxLength}
      />
    </div>
  );
}
