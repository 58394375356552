import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../../utils/store";
import { sendCarToService } from "../../../../requests/car-request";

import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CarLogo } from "../../../../svgs/porsche.svg";
import { ReactComponent as LockIcon } from "../../../../svgs/lock.svg";

import "./index.scss";

function PickACar() {
  const [{ showPickACar, cars, globalShop: shop, user }, dispatch] = useStore();
  const [carPicked, setCarPicked] = useState(undefined);
  const [carConfirmed, setCarConfirmed] = useState(false);
  const [hideCarList, setHideCarList] = useState(false);
  const navigate = useNavigate();
  const radioRefs = useRef([]);
  const carIndexRef = useRef(0);

  const { mutate } = useMutation(sendCarToService, {
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: res => {
      dispatch({ type: "onSuccess", payload: { visible: true } });
      setTimeout(() => {
        dispatch({ type: "onSuccess", payload: { visible: false } });
      }, 2000);
    },
    onError: error => {
      dispatch({ type: "onError", payload: error });
      navigate("/response", { replace: true });
    }
  });

  useEffect(() => {
    radioRefs.current[carIndexRef.current]?.focus();
  }, [showPickACar, hideCarList]);

  const showFinalConfirmation = (car, index) => {
    if (!hasActiveSubscription(car)) {
      return;
    }
    setCarConfirmed(false);
    setCarPicked(car);
    setHideCarList(true);
    carIndexRef.current = index;
  };
  const closePickACarModal = () => {
    dispatch({ type: "pickACarModalToggle", payload: false });
    setCarPicked(undefined);
    setHideCarList(false);
    setCarConfirmed(false);
    carIndexRef.current = 0;
  };
  const confirmCarPickAndService = () => {
    setCarConfirmed(true);
    setHideCarList(false);
  };
  const carToService = () => {
    dispatch({ type: "onLoading", payload: "loading" });
    mutate({ car: carPicked._id, shop: shop._id, user: user._id });
    closePickACarModal();
  };

  function hasActiveSubscription(car) {
    return car.subscriptions.some(
      ({ activated, endDate }) => activated && new Date(endDate) > new Date()
    );
  }
  return shop._id ? (
    <div className={`pick-a-car-modal-container ${showPickACar ? "show" : ""}`}>
      <div className="pick-a-car-modal-close-container">
        <CloseIcon onClick={() => closePickACarModal()} />
      </div>
      <div className={`pick-a-car-modal-car-list`}>
        <div
          className={`service-confirmation-prompt ${
            !hideCarList ? "hide" : ""
          }`}
        >
          <span>wstawiasz</span>
          <span>
            {carPicked?.brand} {carPicked?.model}
          </span>
          <span>do</span>
          <span>{shop?.serviceName.toUpperCase()} ?</span>
          <button
            onClick={() => {
              confirmCarPickAndService();
            }}
          >
            tak
          </button>
          <button
            onClick={() => {
              setHideCarList(false);
            }}
          >
            anuluj
          </button>
        </div>
        {cars.length ? (
          cars.map((car, index) => (
            <div
              name="car"
              key={index}
              className={`modal-car-item ${
                carPicked?.registration === car.registration ? "picked" : ""
              } ${hideCarList ? "hide" : ""}`}
            >
              <input
                ref={el => (radioRefs.current[index] = el)}
                className="modal-car-item-radio"
                name="car"
                type="radio"
                onClick={e => showFinalConfirmation(car, index)}
              />
              <CarLogo />
              <div>
                <span className="modal-car-brand">{car.brand}</span>
                <span className="modal-car-model">{car.model}</span>
              </div>
              <span>{car.year}</span>
              {!hasActiveSubscription(car) && (
                <div className="car-lock">
                  <LockIcon></LockIcon>
                </div>
              )}
              <span className="modal-car-registration">{car.registration}</span>
            </div>
          ))
        ) : (
          <span className="pick-a-car-model-no-cars">brak aut w garażu</span>
        )}
      </div>
      <br />
      <button
        //Todo don't allow to send car to service if cat has no active subscription. It is already secured on backend side
        onClick={() => {
          carToService();
        }}
        style={{ display: carConfirmed ? "block" : "none" }}
      >
        wyślij
      </button>
    </div>
  ) : null;
}

export default PickACar;
