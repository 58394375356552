import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useStore } from "../../../../utils/store";
import { GetMonth, DayOfMonth, GetYear } from "../../../../utils/dateConverter";
import {
  updateService,
  removeService,
} from "../../../../requests/service-request";

import Rating from "../../../Rating";
import { ReactComponent as Shop } from "../../../../svgs/car-shop.svg";
import { ReactComponent as Auto } from "../../../../svgs/garage.svg";
import { ReactComponent as Photos } from "../../../../svgs/photos.svg";
import { ReactComponent as Text } from "../../../../svgs/text.svg";
import PhotoModal from "../ServiceHistory/PhotoModal";
import NoteModal from "../ServiceHistory/NoteModal";
import RatingModal from "./Rating-Modal";
import CarInfoModal from "./../ServiceHistory/CarInfoModal";

import "../ServiceHistory/index.scss";
import "./index.scss";

export default function UserServiceHistory({ fromService }) {
  const [carToShowDetails, setCarToShowDetails] = useState(null);
  const [{ history, user }, dispatch] = useStore();
  const [displayHistory, setDisplayHistory] = useState(undefined);
  const [photoModalOn, setPhotoModalOn] = useState(false);
  const [noteModalOn, setNoteModalOn] = useState(false);
  const [ratingModalOn, setRatingModalOn] = useState(false);
  const [servicePhotos, setServicePhotos] = useState([]);
  const [serviceApt, setServiceApt] = useState(undefined);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let processedHistory = [...history];
    if (fromService) {
      processedHistory = processedHistory.filter((item) => item.archived);
    } else if (location.state?.car) {
      processedHistory = processedHistory.filter(
        (item) => item.car._id === location.state.car
      );
    } else
      processedHistory = processedHistory.sort((a, b) =>
        a.archived === true && b.archived === false ? -1 : 0
      );
    setDisplayHistory(processedHistory);
    //eslint-disable-next-line
  }, []);

  const mutationConfig = {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: (res, variables) => {
      const updatedHistory = [...displayHistory];
      updatedHistory.splice(variables.index, 1);
      setDisplayHistory(updatedHistory);
      dispatch({ type: "onSuccess", payload: { visible: true } });
      setTimeout(() => {
        dispatch({ type: "onSuccess", payload: { visible: false } });
      }, 1000);
    },
    onError: (error) => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  };

  const { mutate } = useMutation(updateService, mutationConfig);
  const { mutate: deleteMutate } = useMutation(removeService, mutationConfig);

  const handlePhotosClick = (service) => {
    setPhotoModalOn(true);
    setServicePhotos(service.attachedPhotos);
  };

  const handleNoteClick = (service) => {
    setNoteModalOn(true);
    setServiceApt(service);
  };

  const handleRatingClick = (service) => {
    setServiceApt(service);
    setRatingModalOn(true);
  };

  const handleReviewSent = () => {
    setRatingModalOn(false);
  };

  const handleAdminRequest = (item, index, approve = false) => {
    setShowActionButtons(false);
    approve
      ? mutate({ _id: item._id, adminApproved: true, index })
      : deleteMutate({ serviceID: item._id, index });
  };

  return (
    <>
      {carToShowDetails !== null ?
        <CarInfoModal {...carToShowDetails} handleClose={() => setCarToShowDetails(null)} /> : null
      }
      <div
        className={
          fromService
            ? "service-history-sub-container"
            : "service-history-container"
        }
      >
        {user.role === "ADMIN" ? null : (
          <RatingModal
            serviceItem={serviceApt}
            checkBoxState={ratingModalOn}
            oncheckBoxStateChange={(checked) => setRatingModalOn(checked)}
            onReviewSent={handleReviewSent}
          />
        )}
        <div className="service-history-list-container">
          {serviceApt ? (
            <NoteModal
              showingNoteModal={noteModalOn}
              setShowingNoteModal={setNoteModalOn}
              serviceItem={serviceApt}
              userPreview={true}
            />
          ) : (
            false
          )}
          <PhotoModal
            showingPhotoModal={photoModalOn}
            setShowingPhotoModal={setPhotoModalOn}
            userPreview={true}
            thumbnailArray={servicePhotos}
          />
          {!displayHistory ? null : displayHistory.length ? (
            displayHistory.map((item, index) => (
              <div className="service-history-item-container-new" key={index}>
                {item.adminApproved && item.archived ? (
                  <div
                    className="rating-click-wrapper"
                    onClick={() => handleRatingClick(item)}
                  >
                    <Rating
                      stars={item.review ? item.review.score : undefined}
                      readonly={true}
                    />
                  </div>
                ) : (
                  false
                )}
                <div className="service-history-item-wrap">
                  <div className="service-history-item-timestamp">
                    <span className="service-timestamp-date">
                      {DayOfMonth(item.createdAt)}
                    </span>
                    <span className="service-timestamp-month">
                      {GetMonth(item.createdAt)}
                    </span>
                    <span className="service-timestamp-year">
                      {GetYear(item.createdAt)}
                    </span>
                  </div>
                  <div
                    className={`service-history-item ${item.archived && item.adminApproved ? "confirmed" : ""
                      }`}
                    onClick={() =>
                      user.role === "ADMIN" && setShowActionButtons(index)
                    }
                  >
                    {user.role === "SERVICE" && fromService ? (
                      <div className="service-item-user-wrapper shop">
                        <Shop />
                        <span>
                          {item.user
                            ? `${item.user?.firstname?.toUpperCase()} ${item.user?.lastname?.toUpperCase()}`
                            : "Użytkownik usuniety"}
                        </span>
                      </div>
                    ) : (
                      <div className="service-item-user-wrapper shop">
                        <Shop />
                        <span>
                          {item.shop?.serviceName?.toUpperCase() ||
                            "Warsztat usunięty"}
                        </span>
                      </div>
                    )}
                    <div className="service-item-car-wrapper" onClick={() => setCarToShowDetails(item.car)} >
                      <Auto />
                      <span>{item.car?.brand || "Auto"}</span>
                      <span>{item.car?.model || "usunięte"}</span>
                    </div>
                  </div>
                </div>
                <div className="service-history-item-status-bar border-bottom-radius user">
                  <div>
                    <span
                      className={`status-desc-user ${item.archived ? "archived" : ""
                        }`}
                    >
                      {item.archived
                        ? "gotowy"
                        : item.shopApproved
                          ? "w serwisie"
                          : "w kolejce"}
                    </span>
                  </div>
                  <div onClick={() => handleNoteClick(item)}>
                    <span className="status-service-desc">opis</span>
                    <Text />
                  </div>
                  <div onClick={() => handlePhotosClick(item)}>
                    <span className="status-service-photos">zdjecia</span>
                    <Photos />
                  </div>
                </div>
                <div
                  className={`item-container-action-buttons-slider ${showActionButtons === index ? "show" : ""
                    }`}
                >
                  <button
                    onClick={() => {
                      handleAdminRequest(item, index);
                    }}
                  >
                    odrzuć
                  </button>
                  <button
                    onClick={() => {
                      handleAdminRequest(item, index, true);
                    }}
                  >
                    akceptuj
                  </button>
                </div>
              </div>
            ))
          ) : user.role === "SERVICE" ? (
            <div className="service-history-no-results">
              <span>brak historii napraw</span>
            </div>
          ) : user.role === "ADMIN" ? (
            <div className="service-history-no-results">
              <span>brak napraw do zatwierdzenia</span>
            </div>
          ) : (
            <div className="service-history-no-results">
              <span>brak historii aut</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
