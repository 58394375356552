import React from "react";

export default function Regulamin() {
  return (
    <p>
      Regulamin świadczenia usługi Motohistoria § 1 Postanowienia ogólne 1.1
      Regulamin określa zasady świadczenia usługi polegającej na udostępnianiu
      Użytkownikom aplikacji do prowadzenia rejestru informacji o czynnościach
      podejmowanych przez serwisy samochodowe w stosunku do pojazdów
      samochodowych wraz z innymi aplikacjami wspomagającymi świadczenie tej
      usługi jako usługami dodatkowymi. Aplikacja do prowadzenia rejestru
      informacji o czynnościach podejmowanych przez serwisy samochodowe będzie
      dostępna w formie przeglądarkowej jako aplikacja typu PWA. 1.2 Korzystanie
      z Aplikacji jest możliwe po zaakceptowaniu przez Użytkownika warunków
      niniejszego Regulaminu. 1.3 Niniejszy Regulamin jest regulaminem, o którym
      mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
      elektroniczną, dalej „Ustawa o świadczeniu usług drogą elektroniczną”. 1.4
      Regulamin jest udostępniony Użytkownikom nieodpłatnie za pośrednictwem
      Platformy w formie, która umożliwia w każdym czasie jego pobranie,
      utrwalenie i wydrukowanie. § 2 Definicje 2.1 Regulamin – niniejszy
      regulamin, określający zasady świadczenia usługi polegającej na
      udostępnianiu Użytkownikom aplikacji do prowadzenia rejestru informacji o
      czynnościach podejmowanych przez serwisy samochodowe w stosunku do
      pojazdów samochodowych wraz z innymi aplikacjami wspomagającymi
      świadczenie tej usługi jako usługami dodatkowymi. 2.2 Platforma
      Motohistoria (Platforma) - strona internetowa prowadzona pod adresem
      https://www.motohistoria.pl, za pomocą, której udostępniana jest Usługa.
      2.3 Usługodawca – administrator Platformy Motohistoria, tj. Łukasz
      Kozłowski prowadzący działalność gospodarczą pod firmą: „Łukasz Kozłowski”
      z siedzibą w Odmy 4 14-120 Dąbrówno, wpisany do Centralnej Ewidencji i
      Informacji o Działalności Gospodarczej pod numerem NIP: 7412166392, REGON:
      526170746, adres e-mail: admin@motohistoria.pl. 2.4 Aplikacja – program
      komputerowy w rozumieniu Ustawy z dnia 4 lutego 1994 r. o prawie autorskim
      i prawach pokrewnych, służący do prowadzenia rejestru informacji o
      czynnościach podejmowanych przez serwisy samochodowe w stosunku do
      pojazdów samochodowych, udostępniany przez Usługodawcę zdalnie w celu
      zainstalowania na własnym urządzeniu Użytkownika w ramach Usługi
      świadczonej na podstawie Regulaminu za pośrednictwem Platformy. Pod
      pojęciem Aplikacji należy rozumieć również inne aplikacje wspomagające jej
      działanie jako usługi dodatkowe. 2.5 Usługa – usługa świadczona przez
      Usługodawcę na rzecz Użytkownika na podstawie niniejszego Regulaminu
      polegająca na udostępnieniu Aplikacji, jej utrzymaniu i rozwoju. Usługa
      oferowana jest w wariancie dla Klienta i w wariancie dla Serwisu. 2.6
      Użytkownik – Klient lub Serwis. 2.7 Klient – właściciel pojazdu
      samochodowego lub użytkownik pojazdu samochodowego dysponujący do niego
      tytułem prawnym. 2.8 Serwis – przedsiębiorca oferujący w ramach
      prowadzonej działalności gospodarczej usługi szeroko rozumianego serwisu
      pojazdów samochodowych. 2.9 Konto – indywidualne, elektroniczne konto w
      ramach Platformy aktywowane dla Użytkownika w celu umożliwienia
      korzystania przez niego z usług Platformy, dostępne po zalogowaniu się
      przez Użytkownika (podaniu loginu oraz hasła), w którym Użytkownik może
      wprowadzić dane pojazdu samochodowego i inne elementy. 2.10 Login –
      oznacza login, którym Użytkownik posługuje się korzystając z Platformy.
      2.11 Hasło – indywidualny ciąg liter, cyfr i znaków stworzony przez
      Użytkownika, używany w celu zabezpieczenia dostępu do Konta. 2.12 Umowa –
      umowa pomiędzy Użytkownikiem a Usługodawcą o świadczenie Usługi
      udostępnianej za pomocą Platformy. 2.13. Umowa o samofakturowanie – umowa
      pomiędzy Użytkownikiem – Serwisem a Usługodawcą, na podstawie której
      wystawiane będą faktury dokumentujące pozyskanie Użytkownika – Klienta i
      ustalające wynagrodzenie – bonus dla Użytkownika – Serwisu, której treść
      stanowi Załącznik Nr 2 do niniejszego Regulaminu. § 3 Warunki zawierania i
      rozwiązywania umów o świadczenie usług drogą elektroniczną 3.1 W celu
      rozpoczęcia korzystania z Usługi konieczne jest dokonanie rejestracji do
      Platformy poprzez: 3.1.1 prawidłowe wypełnienie formularza rejestracyjnego
      zawierającego następujące dane imię i nazwisko Użytkownika lub nazwa w
      przypadku podmiotu niebędącego osobą fizyczną, adres e-mail, adres
      zamieszkania lub siedziby, numer telefonu, NIP; 3.1.2 dokonanie wyboru
      wariantu Usługi (Klient albo Serwis), sposobu płatności; sposób płatności
      może być modyfikowany w trakcie korzystania z Usługi; wszelkie płatności
      odbywają się drogą elektroniczną; 3.1.3 akceptację niniejszego Regulaminu
      wraz z załącznikami oraz regulaminu/ regulaminów dostawcy (operatora)/
      dostawców (operatorów) systemów płatniczych obsługujących transakcje
      płatności za wybraną usługę. 3.2 Formularz rejestracyjny jest udostępniony
      pod adresem www.motohistoria.pl/register. Na stronie
      www.motohistoria.pl/account/settings znajdują się również odsyłacze do
      odpowiednich regulaminów dostawców (operatorów) usług płatniczych oraz
      informacja dotycząca przetwarzania danych osobowych. 3.3 Zakreślenie
      odpowiedniego pola obok formułki „Akceptuję warunki i zasady Regulaminu
      świadczenia usługi Motohistoria”, umieszczonej na stronie internetowej
      www.motohistoria.pl/register oznacza, że Użytkownik zapoznał się i
      akceptuje postanowienia Regulaminu wraz z załącznikami oraz zobowiązuje
      się do przestrzegania Regulaminu wraz z załącznikami. Brak akceptacji
      powyższej klauzuli uniemożliwia korzystanie z Usługi. Dla skorzystania z
      możliwości płatności elektronicznej za wybraną Usługę. Użytkownik ma
      również obowiązek wyrazić zgodę na przekazanie dostawcy (operatorowi)
      usług płatniczych swoich danych osobowych w zakresie niezbędnym dla
      realizacji usługi płatności elektronicznej. 3.4 Użytkownik zobowiązany
      jest do podania w trakcie rejestracji swoich prawdziwych i aktualnych
      danych. Usługodawca zastrzega sobie prawo weryfikacji poprawności
      wprowadzonych danych oraz odmowy rejestracji Usługi w przypadku
      stwierdzenia nieprawidłowości danych. 3.5 Zakończenie procesu rejestracji
      zostanie potwierdzone wysłaniem na podany przez Użytkownika w formularzu
      rejestracyjnym adres e-mail wiadomości zawierającej link aktywacyjny oraz
      login Użytkownika. Loginem Użytkownika jest adres e-mail wskazany w
      formularzu rejestracyjnym. Użytkownik samodzielnie ustala hasło na etapie
      dokonywania rejestracji do Platformy. 3.6 Wszelkie działania podjęte z
      wykorzystaniem loginu i hasła Użytkownika traktowane są jako działania
      Użytkownika. Użytkownik ponosi odpowiedzialność za ujawnienie loginu i
      hasła podmiotom trzecim. Użytkownik jest zobowiązany do zmiany hasła nie
      rzadziej niż co 30 dni. 3.7 Umowa o świadczenie Usługi zawierana jest wraz
      z otrzymaniem przez Użytkownika wiadomości e-mail, która potwierdzi
      prawidłowe uiszczenie przez Użytkownika opłaty. Powyższa wiadomość e-mail
      stanowi też potwierdzenie zawarcia umowy. Korzystanie z Usługi przez
      Klienta wymaga uregulowania opłaty przewidzianej w obowiązującym w dniu
      zawarcia umowy o świadczenie Usługi lub jej przedłużenia cenniku, po
      uprzednim skorzystaniu przez Użytkownika z dostępnej w ramach Platformy
      opcji zakupu oraz wypełnieniu warunków zamówienia. 3.8 Umowa o świadczenie
      usług drogą elektroniczną zostaje zawarta na okres 12 miesięcy. Po tym
      okresie Umowa ulega przedłużeniu na kolejny 12-miesięczny okres, chyba że
      Użytkownik lub Usługodawca, najpóźniej na jeden pełen miesiąc kalendarzowy
      przed upływem powyższego okresu, złoży pisemne oświadczenie o zaprzestaniu
      kontynuacji Umowy. Umowa ulega również rozwiązaniu w przypadku
      nieuiszczenia przez Użytkownika opłaty za kolejny 12-miesięczny okres. 3.9
      Usługodawca ma prawo rozwiązania Umowy ze skutkiem natychmiastowym, w
      przypadku, gdy: 3.9.1 Użytkownik narusza zasady korzystania z Platformy
      lub Aplikacji określone w niniejszym Regulaminie lub prawa autorskie do
      Aplikacji, 3.9.2 Użytkownik podał dane żądane przez Usługodawcę niezgodnie
      z rzeczywistością, 3.9.3 Użytkownik korzystał z Usługi w sposób sprzeczny
      z jej przeznaczeniem, 3.9.4 Użytkownik naruszył podczas rejestracji albo w
      związku z poprzednim korzystaniem z Usługi, prawa osób trzecich lub
      przepisy powszechnie obowiązujące, w tym m.in. dokonał czynu bezprawnego.
      3.10 Usługodawca jest uprawniony do odmowy zawarcia Umowy i świadczenia
      Usługi lub też odstąpienia od zawartej już Umowy, w przypadku, gdy: 3.10.1
      Usługodawca uprzednio rozwiązał Umowę z Użytkownikiem na skutek
      okoliczności, za które odpowiedzialność ponosi Użytkownik, 3.10.2 zachodzi
      uzasadniona obawa, że Usługa wykorzystana będzie do celów niezgodnych z
      charakterem i przeznaczeniem Usługi, 3.10.3 w przypadkach, o których mowa
      w pkt. 3.9.3 – 3.9.4. 3.11 Klient będący konsumentem traci prawo do
      odstąpienia od Umowy na warunkach określonych przez ustawę z dnia 30 maja
      2014 roku o prawach konsumenta po uiszczeniu opłaty i udostępnieniu jemu
      Aplikacji w stanie gotowym do użytkowania (art. 38. ust.1. pkt 1) ustawy o
      prawach konsumenta). § 4 Rodzaje umów o świadczenie usług drogą
      elektroniczną 4.1 Umowa o świadczenie Usług określonych w niniejszym
      Regulaminie jest umową odpłatną dla Klienta. 4.2 Klient zobowiązany jest
      do uiszczania opłat na zasadach określonych w § 7. niniejszego Regulaminu.
      § 5 Prawa i obowiązki Użytkownika 5.1 Użytkownik otrzymuje możliwość
      korzystania z Usługi za pomocą indywidualnego Konta Użytkownika za pomocą
      Loginu i Hasła Użytkownika. 5.2 5.2.1 Użytkownik – Serwis, może korzystać
      z Usługi wyłącznie na potrzeby i w związku z prowadzoną przez siebie
      działalnością gospodarczą, tylko Serwis uprawniony jest do wprowadzania
      nowych danych dotyczących pojazdu klienta związanych z wykonanymi
      czynnościami serwisowymi. 5.2.2 Użytkownik – Klient, może korzystać z
      Usługi zarówno na potrzeby i w związku z prowadzoną przez siebie
      działalnością gospodarczą, jak i jako konsument. 5.3 Wszelkie dane
      wprowadzone przez Użytkownika do Konta w ramach korzystania z Usługi
      stanowią własność Użytkownika i ponosi on za nie pełną odpowiedzialność.
      Usługodawca nie ponosi odpowiedzialności za poprawność danych
      wprowadzanych przez Użytkownika w ramach korzystania z Usługi. Użytkownika
      obowiązuje zakaz wprowadzania do Konta danych o charakterze bezprawnym.
      5.4 Usługodawca informuje, że Platforma, Aplikacja, inne elementy Usługi,
      rozwiązania graficzne chronione są prawami autorskimi, które przysługują
      Usługodawcy. Platforma, Aplikacja i inne elementy Usługi mogą zawierać
      chronione prawem autorskim dokumenty, znaki towarowe oraz inne materiały,
      w szczególności teksty, artykuły, opracowania, zdjęcia, grafikę. 5.5
      Korzystanie z Usług udostępnianych na podstawie niniejszego Regulaminu nie
      oznacza nabycia przez Użytkownika jakichkolwiek praw na dobrach
      niematerialnych do utworów w nim zawartych, z wyjątkiem wyraźnie
      wskazanych w Regulaminie. Użytkownik może korzystać z tych utworów jedynie
      w zakresie dozwolonym w przepisach ustawy z dnia 4 lutego 1994 roku o
      prawie autorskim i prawach pokrewnych. 5.6 Użytkownik w żadnym razie nie
      ma prawa do dokonywania modyfikacji Aplikacji, jej dekompilacji,
      adaptacji, tłumaczenia kodu lub jakichkolwiek innych zmian Aplikacji.
      Użytkownik nie ma prawa usuwać ani też modyfikować jakichkolwiek oznaczeń
      znajdujących się w Aplikacji, w tym znaków towarowych lub innych oznaczeń
      w niej zamieszczonych. 5.7 Platforma oraz Aplikacja mogą być używane
      jedynie do celów zgodnych z obowiązującym prawem. Zakazane jest
      dostarczanie przez i do systemu teleinformatycznego Usługodawcy przez
      Użytkownika oraz umieszczanie w ramach Platformy oraz Konta Użytkownika
      treści o charakterze bezprawnym, w szczególności: 5.7.1 powodujących
      zachwianie pracy lub przeciążenie systemów teleinformatycznych Usługodawcy
      lub innych podmiotów biorących bezpośredni lub pośredni udział w
      świadczeniu usług drogą elektroniczną; 5.7.2 naruszających dobra
      Usługodawcy lub osób trzecich, ogólnie przyjęte normy społeczne lub
      niezgodnych z powszechnie obowiązującymi przepisami prawa. 5.7.3
      naruszających prawa własności intelektualnej osób trzecich; 5.7.4
      naruszających dobra osobiste osób trzecich; 5.7.5 innych treści
      naruszających przepisy obowiązującego na terytorium Rzeczypospolitej
      Polskiej prawa. Usługodawca zastrzega sobie prawo odmowy zamieszczenia w
      Aplikacji opinii Użytkownika, która w jakikolwiek sposób naruszałaby wyżej
      wymienione prawa i/lub dobra lub w jakikolwiek sposób naruszałaby przepisy
      prawa lub dobre obyczaje, a w przypadku jej wcześniejszego zamieszczenia w
      Aplikacji prawo do natychmiastowego jej usunięcia. 5.8 Zawierając umowę z
      Usługodawcą, Użytkownik zobowiązuje się w szczególności do: 5.8.1
      przestrzegania autorskich praw majątkowych oraz praw wynikających z
      rejestracji wynalazków, patentów, znaków towarowych, wzorów użytkowych i
      przemysłowych Usługodawcy, innych Użytkowników oraz osób trzecich, 5.8.2
      powstrzymywania się od jakichkolwiek działań, które naruszałyby prawa
      innych Użytkowników, 5.8.3 powstrzymywania się od jakichkolwiek działań,
      które mogłyby utrudniać lub zakłócać funkcjonowanie Platformy, Aplikacji i
      całej Usługi, a także działań polegających na niszczeniu, zmianie,
      usuwaniu, uszkadzaniu, utrudnianiu dostępu do Kont innych Użytkowników.
      5.9 Użytkownik nie ma prawa, bez uzyskania uprzedniej pisemnej zgody
      Usługodawcy: 5.9.1 udostępniać Aplikacji osobom trzecim do używania pod
      jakimkolwiek tytułem (odpłatnym bądź darmowym), 5.9.2 korzystać z Usługi
      wspólnie z podmiotami trzecimi, 5.9.3 instalować na Platformie
      jakichkolwiek innych programów, narzędzi, itp. 5.10 Użytkownik zobowiązany
      jest do zapoznawania się z treścią komunikatów publikowanych przez
      Usługodawcę i stosowania się do zaleceń Usługodawcy podawanych w tym
      komunikatach, jak również powstrzymania się od korzystania z Aplikacji w
      terminach przeznaczonych na prace konserwacyjne lub mające na celu
      aktualizacje Aplikacji. 5.11. Po rozwiązaniu niniejszej Umowy Konto
      Klienta pozostaje nieaktywne. Dane dotyczące danego pojazdu, wprowadzone w
      trakcie obowiązywania Umowy, pozostają w Aplikacji jako zapis archiwalny
      widoczny dla Użytkownika, lecz bez możliwości dodawania nowych informacji
      o czynnościach serwisowych wykonanych w stosunku do danego pojazdu po
      rozwiązaniu Umowy. § 6 Prawa i obowiązki Usługodawcy 6.1 Usługodawca
      oświadcza, że wszelkie dane przechowywane na Koncie Użytkownika,
      wprowadzone przez Użytkownika w ramach korzystania z Usługi, będą
      chronione przez zabezpieczenie przed ingerencją z zewnątrz, jak również
      nieuprawnionym dostępem wewnętrznym (tj. nieuprawnionych pracowników
      Usługodawcy). Ww. dane przechowywane będą na serwerach wyposażonych w
      profesjonalne systemy podtrzymywania napięcia oraz w celu dodatkowego
      zabezpieczenia przed utratą danych - tworzone będą i przechowywane przez
      Usługodawcę kopie bezpieczeństwa (backup’y) danych zgromadzonych na tych
      serwerach – na zasadach określonych w Regulaminie. 6.2 Usługodawca
      oświadcza, że dane osobowe wprowadzone przez Użytkownika do Konta
      Użytkownika będą przetwarzane przez Usługodawcę zgodnie z obowiązującymi
      przepisami i najwyższymi standardami ochrony danych osobowych, a ich
      wykorzystanie przez Usługodawcę możliwe jest jedynie w dopuszczalnych
      przez prawo granicach, w formie zanonimizowanej, dla celów statystycznych
      oraz związanych z rozwijaniem i polepszaniem jakości Aplikacji. 6.3
      Usługodawca zastrzega sobie prawo do dowolnego rozwoju i modyfikacji
      działania Usługi i funkcjonalności Aplikacji oferowanej w ramach Usługi; o
      wprowadzeniu takich zmian Usługodawca w miarę możliwości będzie informował
      Użytkownika pocztą elektroniczną na adres poczty elektronicznej wskazany
      przez Użytkownika przy rejestracji. 6.4 Usługodawca dołoży wszelkich
      starań by Usługa działała w sposób ciągły i niezakłócony. Jednakże
      Usługodawca zastrzega sobie prawo dokonywania czasowych przerw w działaniu
      Usługi w celach konserwacyjnych, naprawczych, a w szczególności w celach
      aktualizacji i rozbudowy Usługi - o ile te zadania takiej przerwy
      wymagają. W związku z tym Platforma oraz Aplikacja mogą być czasowo
      niedostępne. Usługodawca dołoży wszelkich starań by poinformować
      Użytkownika ze stosownym wyprzedzeniem - za pośrednictwem poczty
      elektronicznej na adres wskazany przez Użytkownika przy rejestracji - o
      ewentualnych przerwach w działaniu Usługi. Ponadto, o planowanych pracach
      konserwacyjnych i innych, Usługodawca informował będzie Użytkownika
      wiadomością wysyłaną na wskazany adres poczty elektronicznej lub w postaci
      komunikatów w koncie Usługi, wyświetlanych po zalogowaniu się Użytkownika
      do Aplikacji. Usługodawca nie ponosi odpowiedzialności za ewentualne
      szkody powstałe na skutek wystąpienia w działaniu Usługi przerw,
      wynikających z wyżej wymienionych przyczyn. 6.5 Usługodawca zastrzega
      sobie prawo do natychmiastowego zablokowania Usługi w przypadkach
      stwierdzenia naruszenia warunków Regulaminu lub przepisów prawa polskiego,
      a także w przypadku stwierdzenia działania na szkodę Usługodawcy; w takim
      przypadku opłaty za usługę uiszczone już przez Użytkownika nie podlegają
      zwrotowi. § 7 Zasady płatności. 7.1 Opłata z tytułu korzystania z Usługi
      jest pobierana w wysokości określonej w cenniku obowiązującym –
      odpowiednio- w dniu zawarcia Umowy oraz w dniu przedłużenia Umowy; 7.2
      zmiana cennika nie dotyczy Użytkowników, którzy dokonali płatności za dany
      okres rozliczeniowy; 7.3 opłata pobierana jest z góry za pośrednictwem
      operatora płatności; 7.4 Użytkownikowi – Serwisowi, który poleci
      korzystanie z Aplikacji Klientowi przysługuje wynagrodzenie – bonus, bonus
      płatny jest raz na kwartał rozliczeniowy w pierwszym miesiącu następującym
      bezpośrednio po kwartale, w którym Klient przystąpił do korzystania z
      Aplikacji; określony w cenniku bonus płatny jest na wskazany przez Serwis
      numer rachunku bankowego; kwartałem rozliczeniowym jest kwartał roku
      kalendarzowego rozpoczynającego się 1 stycznia. 7.5. Użytkownika – Serwis
      i Usługodawcę wiąże Umowa o samofakturowanie, której treść wiążącą dla jej
      Stron stanowi Załącznik Nr 2 do niniejszego Regulaminu. § 8 Aplikacja 8.1
      Usługodawca oświadcza, że przysługują mu wszelkie prawa do Aplikacji
      oferowanej na podstawie niniejszego Regulaminu. 8.2 Użytkownik zobowiązany
      jest do przestrzegania określonych w Regulaminie warunków i zasad
      korzystania z Aplikacji. Użytkownik przyjmuje do wiadomości, że
      niewywiązanie się z powyższego zobowiązania skutkować może naruszeniem
      wyłącznych autorskich praw majątkowych do Aplikacji oraz pociągnięciem
      Użytkownika do odpowiedzialności prawnej z tego tytułu. 8.3 Usługodawca
      dostarcza Aplikację na zasadzie "TAK JAK JEST" i nie ponosi
      odpowiedzialności za jej kompletność lub przydatność do celów Użytkownika,
      ani że Aplikacja będzie działać w każdych warunkach, jakie Użytkownik może
      wybrać dla ich używania ani też, że Aplikacja jest zupełnie wolna od
      wszelkich w bezwzględnym znaczeniu tego pojęcia błędów lub wad.
      Usługodawca nie dostosowuje Aplikacji do konkretnych potrzeb Użytkownika.
      8.4 Udostępnienie Użytkownikowi Aplikacji następuje z serwera Usługodawcy,
      na którym Aplikacja jest zainstalowana, a Użytkownikowi przysługuje
      wyłącznie prawo pobrania Aplikacji oraz jej zainstalowania na swoim
      urządzeniu. 8.5 W ramach zawartej umowy o świadczenie Usługi Usługodawca
      udziela Użytkownikowi niewyłącznej licencji na korzystanie z Aplikacji
      zgodnie z jej przeznaczeniem i tylko i wyłącznie na okres zawarcia umowy.
      Powyższa licencja uprawnia Użytkownika do zwielokrotnienia tymczasowego
      Aplikacji poprzez jej wyświetlanie oraz stosowanie w celu korzystania z
      jej funkcji. 8.6 Użytkownikowi nie przysługuje prawo odpłatnego lub
      nieodpłatnego udostępniania Aplikacji osobom trzecim. § 9 Wymagania
      techniczne, zagrożenia związane z korzystaniem z sieci Internet 9.1
      Minimalne wymagania techniczne umożliwiające prawidłowe korzystanie z
      Usług obejmują: 9.1.1 komputer osobisty lub inny kompatybilny, smartfon
      lub inne urządzenie podobne; 9.1.2 dostęp do sieci Internet; 9.1.3
      posiadanie konta e-mail; 9.1.4 dostęp do witryn www – poprawnie
      skonfigurowana przeglądarki: Chrome w wersji 75 i nowsze Firefox w wersji
      67 i nowsze 9.2 Odmienna lub niekompletna konfiguracja sprzętu
      komputerowego lub oprogramowania od wskazanych powyżej może prowadzić do
      braku możliwości korzystania z Usług. 9.3 Usługodawca nie zapewnia
      warunków technicznych, o których mowa w pkt. 9.1 powyżej. Użytkownik we
      własnym zakresie ponosi koszty zapewnienia wymagań technicznych, w tym
      koszty dostępu do Internetu. 9.4 Korzystanie przez Użytkownika z usług
      świadczonych na podstawie Regulaminu nie wiąże się dla Użytkownika ze
      szczególnymi zagrożeniami związanymi z korzystaniem z usług świadczonych
      drogą elektroniczną, poza tymi, które powszechnie występują przy
      korzystaniu z publicznej sieci teleinformatycznej. § 10 Odpowiedzialność
      Usługodawcy 10.1 Usługodawca nie ponosi odpowiedzialności w szczególności
      za: 10.1.1 jakiekolwiek szkody wyrządzone osobom trzecim, powstałe w
      wyniku korzystania przez Użytkownika z dostępu do Usług w sposób sprzeczny
      z Regulaminem lub obowiązującymi przepisami prawa; 10.1.2 jakiekolwiek
      szkody wyrządzone osobom trzecim będące następstwem podania przez
      Użytkownika nieprawdziwych danych na formularzu rejestracyjnym; 10.1.3
      szkody i straty poniesione przez Użytkownika będące następstwem zagrożeń
      związanych z korzystaniem z sieci Internet, w szczególności działania
      wirusów komputerowych i włamania do systemu komputerowego; 10.1.4 szkody
      spowodowane: problemami telekomunikacyjnymi, niedziałaniem albo wadliwym
      działaniem oprogramowania firm trzecich, w tym działaniem wirusów
      komputerowych, niewłaściwą konfiguracją Usługi po stronie Użytkownika,
      wadami sprzętu komputerowego lub innych urządzeń Użytkownika, brakiem lub
      przerwami w dostępie do Internetu po stronie Użytkownika, nieprawidłową
      obsługą Aplikacji przez Użytkownika, błędami lub brakami w danych
      wprowadzonych przez Użytkownika, ingerencją w Aplikację lub Konto
      Użytkownika przez osoby nieuprawnione (chyba, że nastąpiło to z winy
      Usługodawcy), w szczególności powstałą w związku z udostępnieniem przez
      Użytkownika osobom nieuprawnionym danych Konta Użytkownika lub danych
      uwierzytelniających lub też brakiem u Użytkownika odpowiednich
      zabezpieczeń przed przejęciem takich danych. 10.2 Usługodawca nie ponosi
      odpowiedzialności za działania osób trzecich ani za wykorzystanie przez
      nie danych Użytkowników niezgodnie z celem działania Platformy. 10.3
      Wszelka odpowiedzialność Usługodawcy związana z niewykonaniem lub
      nienależytym wykonaniem Usługi ze wszystkich tytułów (w tym za utratę
      danych) jest ograniczona do rzeczywistych strat poniesionych przez
      Użytkownika. 10.4 Bez względu na jakiekolwiek szkody wynikłe lub w
      jakikolwiek sposób związane z używaniem lub niemożnością używania
      Oprogramowania, które Użytkownik lub osoby trzecie mogą ponieść z
      jakiegokolwiek powodu, całkowita odpowiedzialność Użytkownika z
      jakiegokolwiek tytułu jest ograniczona do kwoty stanowiącej równowartość 2
      – krotności opłaty rocznej za Usługę. Użytkownikowi nie służy prawo do
      odszkodowania za utracone korzyści ani prawo dochodzenia odszkodowania
      uzupełniającego. 10.5 Ponadto Usługodawca nie ponosi odpowiedzialności za
      jakiekolwiek korzyści, które Użytkownik mógłby osiągnąć, gdyby mu szkody
      nie wyrządzono. Ograniczenie odpowiedzialności nie dotyczy szkód
      wyrządzonych Użytkownikowi z winy umyślnej Usługodawcy. § 11 Reklamacje
      11.1 Użytkownikowi przysługuje prawo składania reklamacji dotyczących
      jakości świadczenia Usług. W szczególności reklamacje mogą być składane z
      tytułu braku lub nienależytego dostępu do Usług. 11.2 Reklamacje składane
      są drogą elektroniczną na następujący adres e-mailowy
      admin@motohistoria.pl. 11.3 Prawidłowo złożona reklamacja powinna
      zawierać, co najmniej następujące dane: 11.3.1 imię i nazwisko (nazwę)
      Użytkownika, 11.3.2 przedmiot reklamacji, 11.3.3 okoliczności
      uzasadniające reklamację, 11.3.4 adres poczty elektronicznej, na który ma
      zostać przesłana decyzja Usługodawcy dotycząca reklamacji. 11.4
      Usługodawca dołoży starań, aby reklamacje były rozpatrzone w terminie 7
      dni roboczych od ich otrzymania od Użytkownika. Usługodawca zawiadomi
      Użytkownika o swojej decyzji zapadłej w wyniku rozpatrzenia reklamacji za
      pośrednictwem poczty elektronicznej na adres podany w reklamacji. 11.5
      Wszelkie problemy techniczne mogą być zgłaszane przez Użytkowników drogą
      elektroniczną na adres admin@motohistoria.pl. § 12 Pozasądowe sposoby
      rozpatrywania reklamacji i dochodzenia roszczeń przez Użytkowników
      będących konsumentami. 12.1. Szczegółowe informacje dotyczące możliwości
      skorzystania przez Użytkownika będącego konsumentem (dalej: Konsument) z
      pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz
      zasady dostępu do tych procedur dostępne są w siedzibach oraz na stronach
      internetowych powiatowych (miejskich) rzeczników konsumentów, organizacji
      społecznych, do których zadań statutowych należy ochrona konsumentów,
      Wojewódzkich Inspektoratów Inspekcji Handlowej oraz pod następującymi
      adresami internetowymi Urzędu Ochrony Konkurencji i Konsumentów:
      http://www.uokik.gov.pl/spory_konsumenckie.php,
      http://www.uokik.gov.pl/sprawy_indywidualne.php oraz
      http://www.uokik.gov.pl/wazne_adresy.php. 12.2. Konsument posiada
      następujące przykładowe możliwości skorzystania z pozasądowych sposobów
      rozpatrywania reklamacji i dochodzenia roszczeń. 12.2.1. Konsument
      uprawniony jest do zwrócenia się do stałego polubownego sądu
      konsumenckiego, o którym mowa w art. 37 ustawy z dnia 15 grudnia 2000 r. o
      Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.), z wnioskiem o
      rozstrzygnięcie sporu wynikłego z Umowy zawartej z Usługodawcą. 12.2.2.
      Konsument uprawniony jest do zwrócenia się do wojewódzkiego inspektora
      Inspekcji Handlowej, zgodnie z art. 36 ustawy z dnia 15 grudnia 2000 r. o
      Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.), z wnioskiem o
      wszczęcie postępowania mediacyjnego w sprawie polubownego zakończenia
      sporu między Konsumentem a Usługodawcą. 12.2.3. Konsument może uzyskać
      bezpłatną pomoc w sprawie rozstrzygnięcia sporu między nim a Usługodawcą,
      korzystając także z bezpłatnej pomocy powiatowego (miejskiego) rzecznika
      konsumentów lub organizacji społecznej, do której zadań statutowych należy
      ochrona konsumentów (m.in. Federacja Konsumentów, Stowarzyszenie
      Konsumentów Polskich). § 13. Postanowienia końcowe 13.1 Wprowadzenie przez
      Usługodawcę nowego lub zmienionego Regulaminu lub cennika pozostaje bez
      wpływu na treść stosunków umownych powstałych przed tą zmianą, z
      zastrzeżeniem postanowień ust. 12.2-12.3 poniżej. 13.2 Regulamin może ulec
      zmianie, o czym Użytkownicy zostaną powiadomieni w sposób zgodny z
      przepisami obowiązującego na terytorium Rzeczypospolitej Polskiej prawa.
      13.3 W przypadku, gdy zmiana Regulaminu dotyczy Użytkowników
      korzystających z Usługi w ramach obowiązującej Umowy, Użytkownikowi, w
      terminie 30 dni od dnia doręczenia mu powiadomienia na temat zmiany
      Regulaminu, przysługuje prawo do wypowiedzenia Umowy o świadczenie Usługi
      z 30-dniowym terminem wypowiedzenia, bez konieczności uiszczenia żadnego
      odszkodowania. W przypadku braku wypowiedzenia Umowy w powyższym terminie
      nowy (zmieniony) Regulamin obowiązuje Użytkownika. Wypowiedzenie powinno
      zostać złożone w formie pisemnej i przesłane na adres poczty
      elektronicznej: admin@motohistoria.pl. 13.4 Wszelkie powiadomienia
      związane z niniejszą Umową, przeznaczone dla Użytkownika, mogą być
      dokonywane w formie wiadomości elektronicznej skierowanej na adres poczty
      elektronicznej Użytkownika wskazany w formularzu rejestrowym, o ile
      niniejszy Regulamin nie przewidują wyraźnie wymogu zachowania formy
      pisemnej lub dokumentowej. 13.5 Użytkownik zobowiązany jest do
      powiadamiania Usługodawcy o każdej zmianie adresów lub innych danych
      identyfikujących Użytkownika, zawartych w formularzu rejestracyjnym. W
      braku takiego powiadomienia, przesyłka lub wiadomość skierowana na adres
      podany w formularzu rejestracyjnym będzie uznana za skutecznie doręczoną.
      13.6 Wszelkie spory wynikłe z Umowy będą rozstrzygane przed sądem
      właściwym dla siedziby Usługodawcy. 13.7 Umowa o świadczenie Usługi
      zawierana jest w języku polskim. 13.8 Prawem właściwym dla umowy o
      świadczenie Usług jest prawo obowiązujące na terytorium Rzeczypospolitej
      Polskiej. 13.9 Niniejszy Regulamin wchodzi w życie dnia 02.09.2023 r.
      Załącznik Nr 1 CENNIK Opłata uiszcza przez Klienta za udostępnienie
      Aplikacji – 35,- zł brutto za jeden pojazd samochodowy. Bonus dla Serwisu
      za polecenie Klienta, który Aplikację pobierze i zacznie z niej efektywnie
      korzystać – 15,- zł brutto za jednego Klienta. Załącznik Nr 2 Umowa o
      samofakturowanie § 1. Działając na podstawie art. 106d ust. 1 ustawy z
      dnia 11 marca 2004 r. o podatku od towarów i usług – dalej u.p.t.u.,
      Użytkownik – Serwis upoważnia Usługodawcę do wystawiania w imieniu i na
      rzecz Użytkownika - Serwisu faktur dokumentujących dokonywanie przez
      Użytkownika - Serwis sprzedaży na rzecz Usługodawcy usługi pozyskiwania
      Klientów w okresach kwartalnych (w tym dotyczących takiej sprzedaży
      faktur, o których mowa w art. 106b ust. 1 pkt 4 u.p.t.u., faktur
      korygujących oraz duplikatów), a Usługodawca upoważnienie to przyjmuje. §
      2. Faktury, o których mowa w § 1, Usługodawca obowiązany jest wystawiać
      najpóźniej 7. dnia po zakończeniu kwartału, którego dotyczą. Zgodnie z
      art. 106e ust. 1 pkt 17 u.p.t.u., faktury te zawierać będą wyraz
      "samofakturowanie". § 3 W terminie 7 dni od dnia wystawienia Usługodawca
      obowiązany jest przekazać Użytkownikowi – Serwisowi faktury wystawione w
      jego imieniu i na jego rzecz. Faktury będą przekazane w formie
      elektronicznej na obowiązujący zgodnie z Regulaminem adres poczty
      elektronicznej Użytkownika - Serwisu. Terminowo przekazane faktury, które
      nie zostaną zakwestionowane przez Użytkownika - Serwisu w terminie 7 dni
      od dnia ich przekazania, uważa się za zatwierdzone przez Użytkownika -
      Serwisu.
    </p>
  );
}
