export const loginRequest = formData => {
  return fetch(`/api/auth/login`, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      throw new Error((await res.json()).message);
    }
    return await res.json();
  });
};
