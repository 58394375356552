import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../utils/store";
import { UserFormDataModel } from "../../../utils/modelHelper";
import { userUpdateRequest } from "../../../requests/udpate-request";
import Select from "../../Select";
import FormField from "./UserFormField";
import { COUNTIES } from "../../../constants/counties";

import { default as s } from "./index.module.scss";

export default function AccountUser() {
  const navigate = useNavigate();
  const [{ user }, dispatch] = useStore();
  const [userForm, setUserForm] = useState(UserFormDataModel(user));
  const [saveButtonText, setSaveButtonText] = useState("zapisz");

  const { mutate: mutateUserUpdate } = useMutation(userUpdateRequest, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: res => {
      dispatch({ type: "updateUser", payload: res.data });
      setSaveButtonText("OK");
      dispatch({ type: "onSuccess", payload: { visible: true } });
      setTimeout(() => {
        dispatch({ type: "onSuccess", payload: { visible: false } });
        setSaveButtonText("zapis");
      }, 2000);
    },
    onError: error => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    }
  });

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleUserUpdate = () => {
    // dont sent request if values unchanged
    const allTheSame = Object.keys(userForm).every(key => {
      return typeof userForm[key] === "object"
        ? JSON.stringify(user[key]) === JSON.stringify(userForm[key])
        : userForm[key] === user[key];
    });
    if (!allTheSame) {
      mutateUserUpdate({ userForm, userID: user.id });
    }
  };

  return (
    <div className={s["user-container"]}>
      <FormField
        placeholder="Imię"
        classes={[s["userField"], s["col-2"]]}
        userKey={userForm.firstname}
        onUserFormSet={firstname => setUserForm({ ...userForm, firstname })}
      />
      <FormField
        placeholder="Nazwisko"
        classes={[s["userField"], s["col-2"]]}
        userKey={userForm.lastname}
        onUserFormSet={lastname => setUserForm({ ...userForm, lastname })}
      />
      <FormField
        placeholder="Nazwa Warsztatu"
        classes={[
          s["userField"],
          s["col-2"],
          `${user.role === "SERVICE" ? "" : s.hidden}`
        ]}
        userKey={userForm.serviceName}
        onUserFormSet={serviceName => setUserForm({ ...userForm, serviceName })}
      />
      <FormField
        placeholder="NIP"
        classes={[
          s["userField"],
          s["col-2"],
          `${user.role === "SERVICE" ? "" : s.hidden}`
        ]}
        userKey={userForm.tin}
        maxLength="10"
        onUserFormSet={taxID => setUserForm({ ...userForm, tin: taxID })}
      />
      <div className={`${s["userField"]} ${s["col-2"]}`}>
        <Select
          setParentState={value => setUserForm({ ...userForm, county: value })}
          list={COUNTIES}
          defaultTitle={userForm.county || "Województwo"}
        />
      </div>
      <FormField
        placeholder="Miejscowość"
        classes={[s["userField"]]}
        userKey={userForm.city}
        onUserFormSet={city => setUserForm({ ...userForm, city })}
      />
      <FormField
        placeholder="Kod"
        classes={[s["userField"]]}
        userKey={userForm.postalCode}
        onUserFormSet={postalCode => setUserForm({ ...userForm, postalCode })}
      />
      <FormField
        placeholder="Ulica"
        classes={[s["userField"]]}
        userKey={userForm.street}
        onUserFormSet={street => setUserForm({ ...userForm, street })}
      />
      <FormField
        placeholder="Numer"
        classes={[s["userField"]]}
        userKey={userForm.streetNo}
        onUserFormSet={streetNo => setUserForm({ ...userForm, streetNo })}
      />
      <FormField
        placeholder="Numer Telefonu"
        classes={[s["userField"], s["col-2"]]}
        maxLength="11"
        userKey={userForm.phone}
        onUserFormSet={phone => setUserForm({ ...userForm, phone })}
      />
      <FormField
        placeholder="Email"
        classes={[s["userField"], s["col-2"]]}
        userKey={userForm.email}
        onUserFormSet={email => setUserForm({ ...userForm, email })}
      />
      <FormField
        placeholder="Numer Konta Bankowego"
        classes={[
          s["userField"],
          s["col-2"],
          `${user.role === "SERVICE" ? "" : s.hidden}`
        ]}
        userKey={userForm.bankAccount}
        maxLength="26"
        onUserFormSet={bankAccount => setUserForm({ ...userForm, bankAccount })}
      />
      <button onClick={handleUserUpdate}>{saveButtonText}</button>
    </div>
  );
}
