export const resetPasswordRequest = formData => {
  return fetch(`/api/user/password-reset`, {
    method: "PUT",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      throw new Error((await res.json()).message);
    }
    return res.json();
  });
};

export const checkResetTokenRequest = formData => {
  return fetch(`/api/user/password-reset/${formData.token}`, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      throw new Error((await res.json()).message);
    }
    return res.json();
  });
};

export const confirmPasswordResetRequest = formData => {
  return fetch(`/api/user/confirm-reset`, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      throw new Error((await res.json()).message);
    }
    return res.json();
  });
};
