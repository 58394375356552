import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../../utils/store";
import { GetMonth, DayOfMonth, GetYear } from "../../../../utils/dateConverter";
import { updateServiceMultiPart } from "../../../../requests/service-request";
import NoteModal from "./NoteModal";
import PhotoModal from "./PhotoModal";
import CarInfoModal from "./CarInfoModal";

import { ReactComponent as Photos } from "../../../../svgs/camera.svg";
import { ReactComponent as Text } from "../../../../svgs/text.svg";
import { ReactComponent as User } from "../../../../svgs/account.svg";
import { ReactComponent as Auto } from "../../../../svgs/garage.svg";

import "./index.scss";

export default function EditService({ serviceItem, onServiceItemChange }) {
  const [carToShowDetails, setCarToShowDetails] = useState(null);
  const [isNoteModal, setIsNoteModal] = useState(false);
  const [isPhotoModal, setIsPhotoModal] = useState(false);
  const [thumbnailsArray, setThumbnailArray] = useState(
    serviceItem?.attachedPhotos || []
  );
  const [note, setNote] = useState(
    serviceItem?.serviceDescription || undefined
  );
  const [{ history }, dispatch] = useStore();
  const navigate = useNavigate();

  const { mutate } = useMutation(updateServiceMultiPart, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: res => {
      dispatch({ type: "onSuccess", payload: { visible: true } });
      onServiceItemChange(res.data);
      const newHistory = [...history];
      const itemIndex = history.findIndex(item => item._id === res.data._id);
      if (itemIndex !== -1) {
        newHistory[itemIndex] = res.data;
        dispatch({ type: "updateHistory", payload: newHistory });
      } else {
        throw new Error("Nie znaleziono wizyty serwisowej");
      }
      setTimeout(() => {
        dispatch({ type: "onSuccess", payload: { visible: false } });
      }, 2000);
    },
    onError: error => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    }
  });
  const handleServiceSaveMultipart = () => {
    const uploadedByClient = thumbnailsArray.filter(
      item => typeof item === "object"
    );
    if (uploadedByClient.length || note !== undefined) {
      const formData = new FormData();
      formData.append("_id", serviceItem._id);
      if (note !== undefined) {
        formData.append("serviceDescription", note);
      }
      uploadedByClient.forEach(thumbnail => {
        formData.append("photos", thumbnail.file, thumbnail.name);
      });
      mutate(formData);
    }
  };
  const handleConfirmService = () => {
    const formData = new FormData();
    formData.append("_id", serviceItem._id);
    formData.append("archived", true);
    mutate(formData);
  };
  return (
    <>
    { carToShowDetails !== null ?
      <CarInfoModal { ...carToShowDetails } handleClose={ () => setCarToShowDetails(null) } /> : null
    }
      <div className="service-history-list-container">
        {serviceItem ? (
          <>
            <NoteModal
              showingNoteModal={isNoteModal}
              setShowingNoteModal={action => setIsNoteModal(action)}
              serviceItem={serviceItem}
              onAddNote={note => setNote(note)}
            />
            <PhotoModal
              showingPhotoModal={isPhotoModal}
              setShowingPhotoModal={action => setIsPhotoModal(action)}
              onThumbnailSet={setterF => setThumbnailArray(setterF)}
              serviceItem={serviceItem}
              thumbnailArray={thumbnailsArray}
              onMutate={body => mutate(body)}
            />
            <div className="service-history-item-container">
              <div className="service-history-item-timestamp">
                <span className="service-timestamp-date">
                  {DayOfMonth(serviceItem.createdAt)}
                </span>
                <span className="service-timestamp-month">
                  {GetMonth(serviceItem.createdAt)}
                </span>
                <span className="service-timestamp-year">
                  {GetYear(serviceItem.createdAt)}
                </span>
              </div>
              <div className="service-history-item">
                <div className="service-item-user-wrapper">
                  <User />
                  <span>
                    {serviceItem.user?.firstname
                      ? serviceItem.user.firstname.toUpperCase()
                      : "Użytkownik"}
                  </span>
                  <span>
                    {serviceItem.user?.lastname
                      ? serviceItem.user.lastname.toUpperCase()
                      : "usunięty"}
                  </span>
                </div>
                <div className="service-item-car-wrapper" onClick={() => setCarToShowDetails(serviceItem.car) } >
                  <Auto />
                  <span>{serviceItem.car.brand}</span>
                  <span>{serviceItem.car.model}</span>
                </div>
              </div>
              <div className="service-history-item-status-bar with-hover">
                <div onClick={() => setIsNoteModal(true)}>
                  <span className="status-service-desc">opis</span>
                  <Text />
                </div>
                <div onClick={() => setIsPhotoModal(true)}>
                  <span className="status-service-photos">zdjecia</span>
                  <Photos />
                </div>
                <div
                  className="status-bar-save-action-wraper"
                  onClick={() => handleServiceSaveMultipart()}
                >
                  <span className="status-desc" style={{ gridColumn: "1/3" }}>
                    zapisz
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              alignSelf: "center",
              gridRow: "1/3",
              textAlign: "center",
              font: "300 1rem Russo One",
              color: "var(--mainFontColor)"
            }}
          >
            Brak aut w warsztacie, wybierz z listy oczekujących lub poczekaj na
            zgłoszenia.
          </div>
        )}
      </div>
      {serviceItem ? (
        <button
          className="service-history-confirmed-service"
          onClick={() => {
            serviceItem.serviceDescription && handleConfirmService();
          }}
        >
          zakończ serwis auta
        </button>
      ) : null}
    </>
  );
}
