import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../../utils/store";
import { removeUserRequest } from "../../../../requests/admin-request";
import { GetYear, GetMonth } from "../../../../utils/dateConverter";

import { ReactComponent as Shop } from "../../../../svgs/car-shop.svg";
import { ReactComponent as Car } from "../../../../svgs/garage.svg";
import { ReactComponent as Account } from "../../../../svgs/user-account.svg";
import { ReactComponent as Admin } from "../../../../svgs/admin.svg";
import { ReactComponent as Email } from "../../../../svgs/email.svg";
import { ReactComponent as Trash } from "../../../../svgs/trash.svg";
import { ReactComponent as Hamburger } from "../../../../svgs/Hamburger.svg";
import { ReactComponent as Phone } from "../../../../svgs/call.svg";
import { ReactComponent as Bank } from "../../../../svgs/bank.svg";

import "../ServiceHistory/index.scss";
import "../UserServiceHistory/index.scss";
import "./index.scss";

export default function AdminAccounts() {
  const [{ appUsers }, dispatch] = useStore();
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showUserIncome, setShowUserIncome] = useState(false);
  const navigate = useNavigate();

  const { mutate } = useMutation(removeUserRequest, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: async (res, variables) => {
      const newAppUsers = Object.assign({}, appUsers);
      delete newAppUsers[variables.index];
      dispatch({ type: "updateAppUsers", payload: newAppUsers });
    },
    onError: (error) => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  });

  const removeUserHandler = (id, index) => {
    if (window.confirm("Chcesz usunać?")) {
      mutate({ id, index });
    }
  };
  const handleUserDetails = (user) => {
    setShowUserDetails(user._id);
    setShowUserIncome(undefined);
  };
  const handleUserIncome = (user) => {
    setShowUserIncome(user._id);
  };

  const printRoleIcon = (role) => {
    return role === "SERVICE" ? (
      <Shop />
    ) : role === "USER" ? (
      <Account />
    ) : (
      <Admin />
    );
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="service-history-container ">
      <div className="service-history-list-container">
        {Object.assign([], appUsers).length ? (
          Object.assign([], appUsers).map((item, index) => {
            const monthCounts = [];
            item.carsFromAffiliation?.forEach((car) => {
              if (car.subscriptions) {
                const activeSubscriptions = car.subscriptions.filter(
                  (sub) => sub.activated
                );
                activeSubscriptions.forEach((sub) => {
                  const startDate = new Date(sub.startDate);
                  const monthYearKey = `${
                    monthNames[startDate.getMonth()]
                  }-${startDate.getFullYear()}`;

                  const existingMonth = monthCounts.find(
                    (item) => item.month === monthYearKey
                  );
                  if (existingMonth) {
                    existingMonth.count++;
                  } else {
                    monthCounts.push({ month: monthYearKey, count: 1 });
                  }
                });
              }
            });
            return (
              <div className="service-history-item-container" key={index}>
                <div className="service-history-item-timestamp">
                  <span className="service-timestamp-month  admin-accounts">
                    {item.createdAt ? GetMonth(item.createdAt) : "Dawno"}
                  </span>
                  <span className="service-timestamp-year admin-accounts">
                    {item.createdAt ? GetYear(item.createdAt) : "temu"}
                  </span>
                </div>
                <div className={`service-history-item`}>
                  <Hamburger
                    className="user-details-button"
                    onClick={() => handleUserDetails(item)}
                  />
                  <div className="service-item-user-wrapper shop">
                    {printRoleIcon(item.role)}
                    <span>{item.firstname?.toUpperCase()}</span>
                    <span>{item.lastname?.toUpperCase()}</span>
                  </div>
                  <div className="service-item-car-wrapper">
                    <Email />
                    <span>{item.email}</span>
                  </div>
                </div>
                <div className="service-history-item-status-bar admin">
                  <div>
                    <span
                      className={`status-desc-user ${
                        item.affiliation ? "affiliated" : ""
                      }`}
                    >
                      {item.affiliation
                        ? `${item.affiliation}`
                        : "bez polecenia"}
                    </span>
                  </div>
                  <div className="remove-account">
                    <Trash onClick={() => removeUserHandler(item._id, index)} />
                  </div>
                </div>
                <div
                  className={`user-details-container ${
                    showUserDetails === item._id ? "show" : ""
                  }`}
                >
                  <div
                    className="close-user-details"
                    onClick={() => setShowUserDetails(false)}
                  >
                    &times;
                  </div>
                  <div className="icon-wrapper">{printRoleIcon(item.role)}</div>
                  <div className="details-span-wrapper">
                    <span>{item.firstname?.toUpperCase()}</span>
                    <span>{item.lastname?.toUpperCase()}</span>
                  </div>
                  <div className="icon-wrapper">
                    <Email />
                  </div>
                  <div className="details-span-wrapper">
                    <span>{item.email}</span>
                  </div>
                  <div className="icon-wrapper">
                    <Phone />
                  </div>
                  <div className="details-span-wrapper">
                    <span>{item.phone}</span>
                  </div>
                  <div className="icon-wrapper">
                    <span
                      style={{ textAlign: "center", color: "var(--primary)" }}
                    >
                      NIP
                    </span>
                  </div>
                  <div className="details-span-wrapper">
                    <span>{item.tin}</span>
                  </div>
                  <div className="icon-wrapper">
                    <Bank />
                  </div>
                  <div className="details-span-wrapper">
                    <span>{item.bankAccount}</span>
                  </div>
                  <div className="icon-wrapper">
                    <Car />
                  </div>
                  <div className="details-span-wrapper">
                    <div className="details-income-wrapper">
                      <span onClick={() => handleUserIncome(item)}>
                        {monthCounts?.[monthCounts.length - 1]?.month}{" "}
                        {monthCounts?.[monthCounts.length - 1]?.count}
                      </span>
                      <div
                        className={`details-income ${
                          showUserIncome === item._id ? "show" : ""
                        }`}
                      >
                        {item.role === "SERVICE" && (
                          <>
                            <table>
                              <tbody>
                                {monthCounts.map((item) => {
                                  return (
                                    <tr key={item.month}>
                                      <td>
                                        <span>{item.month}</span>
                                      </td>
                                      <td>
                                        <span>{item.count}</span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="service-history-no-results">
            brak użytkowników w bazie
          </div>
        )}
      </div>
    </div>
  );
}
