import { useStore } from "../../utils/store";
import "./error.scss";

export default function ErrorModa() {
  const [{ error }] = useStore();

  return (
    <div className={`error-modal-container ${error?.message ? "show" : ""}`}>
      <div
        className="swal2-icon swal2-error swal2-animate-error-icon"
        style={{ display: "flex" }}
      >
        <span className="swal2-x-mark">
          <span className="swal2-x-mark-line-left"></span>
          <span className="swal2-x-mark-line-right"></span>
        </span>
      </div>
      <label htmlFor="" className="error-modal-message-header">
        Błąd serwera:
      </label>
      <p className="error-modal-message">{error?.message}</p>
    </div>
  );
}
