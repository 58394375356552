import React, { useState, useEffect } from "react";

import { ReactComponent as Upload } from "../../../../svgs/upload.svg";
import { ReactComponent as Photo } from "../../../../svgs/photo.svg";
import { ReactComponent as Image } from "../../../../svgs/dummy-image.svg";
import { ReactComponent as Bin } from "../../../../svgs/trash.svg";

import "./photo-modal.scss";

export default function PhotoModal({
  showingPhotoModal,
  setShowingPhotoModal,
  userPreview,
  serviceItem,
  thumbnailArray,
  onThumbnailSet,
  onMutate,
}) {
  const [isPreviewed, setIsPreviewed] = useState(false);
  const [objURLOrPath, setObjURLOrPath] = useState();
  const [deleteIndex, setDeleteIndex] = useState(thumbnailArray.length - 1);

  useEffect(() => {
    if (thumbnailArray.length) {
      setObjURLOrPath(
        thumbnailArray[thumbnailArray.length - 1].objectURL ||
          thumbnailArray[thumbnailArray.length - 1]
      );
      setIsPreviewed(true);
    } else {
      setIsPreviewed(false);
    }
  }, [thumbnailArray]);

  const chooseImageHandler = (event) => {
    const image = event.target.files[0];
    if (image) {
      const URL = window.URL || window.webkitURL;
      const source = URL.createObjectURL(image);
      onThumbnailSet((prev) => [
        ...prev,
        { objectURL: source, name: image.name, file: image },
      ]);
      setDeleteIndex(thumbnailArray.length);
    }
  };

  const handleModalClose = () => {
    setShowingPhotoModal(false);
  };

  const handleThumbnailClick = (source, index) => {
    setObjURLOrPath(source);
    setDeleteIndex(index);
  };

  const handlePhotoDelete = (path) => {
    if (!path.startsWith("blob")) {
      serviceItem.attachedPhotos.splice(deleteIndex, 1);
      const formData = new FormData();
      formData.append("_id", serviceItem._id);
      formData.append("pathToFile", path);
      formData.append("attachedPhotos[]", serviceItem.attachedPhotos);
      onMutate(formData);
    } else {
      const URL = window.URL || window.webkitURL;
      URL.revokeObjectURL(objURLOrPath);
    }
    const newArray = [...thumbnailArray];
    newArray.splice(deleteIndex, 1);
    onThumbnailSet((prev) => newArray);
    setDeleteIndex(thumbnailArray.length - 2);
  };

  return (
    <div
      className={`service-history-list-container-photo-modal ${
        showingPhotoModal ? "show" : ""
      }`}
    >
      <div className="note-modal-close">
        <div
          className="service-history-list-container-photo-modal-close"
          onClick={() => handleModalClose()}
        ></div>
      </div>
      <div className="photo-modal-main-container">
        <div className="photo-modal-main-image-container">
          <div className={`dummmy-placeholder ${isPreviewed ? "hide" : ""}`}>
            <Image />
          </div>
          <img
            className={`photo-modal-main-image ${
              isPreviewed ? "previewed" : ""
            }`}
            src={objURLOrPath}
            alt=""
          />
        </div>
        {userPreview ? (
          false
        ) : (
          <div className="photo-modal-buttons-section">
            <button>
              <Upload />
              <span>Dodaj zdjecie</span>
              <input
                type="file"
                onChange={(e) => {
                  chooseImageHandler(e);
                }}
                accept="image/*"
                multiple
              />
            </button>
            <button
              className="photo-modal-main-image-delete"
              onClick={(e) =>
                objURLOrPath ? handlePhotoDelete(objURLOrPath) : false
              }
            >
              <Bin />
              <span>Usuń zdjęcie</span>
            </button>
            <button>
              <Photo />
              <span>Zrób zdjęcie</span>
              <input
                type="file"
                onChange={(e) => {
                  chooseImageHandler(e);
                }}
                accept="image/*"
                capture
              />
            </button>
          </div>
        )}
      </div>
      <div className="photo-modal-image-roll">
        {thumbnailArray.length ? (
          thumbnailArray.map((thumbnail, index) => (
            <div className="photo-modal-image-box" key={index}>
              <img
                onClick={(e) =>
                  handleThumbnailClick(thumbnail.objectURL || thumbnail, index)
                }
                className="photo-modal-thumbnail"
                src={thumbnail.objectURL || thumbnail}
                alt={thumbnail.name || thumbnail.slice(-30)}
              />
            </div>
          ))
        ) : (
          <>
            <div className="photo-modal-image-box empty"></div>
            <div className="photo-modal-image-box empty"></div>
            <div className="photo-modal-image-box empty"></div>
            <div className="photo-modal-image-box empty"></div>
            <div className="photo-modal-image-box empty"></div>
            <div className="photo-modal-image-box empty"></div>
            <div className="photo-modal-image-box empty"></div>
            <div className="photo-modal-image-box empty"></div>
          </>
        )}
      </div>
    </div>
  );
}
