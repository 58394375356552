import { CustomError } from "./customError";

export const getAllServices = formData => {
  return fetch(`/api/service/running-services`, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const getServicesByCar = carID => {
  return fetch(`/api/service/service-history/${carID}`, {
    method: "POST"
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const updateService = formData => {
  return fetch(`/api/service/update`, {
    method: "PUT",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const updateServiceMultiPart = formData => {
  return fetch(`/api/service/update`, {
    method: "PUT",
    body: formData
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const removeService = formData => {
  return fetch(`/api/service/remove/${formData.serviceID}`, {
    method: "DELETE"
  }).then(async res => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};
