import React from "react";

export default function Rodo1() {
  return (
    <p>
      Wyrażam zgodę na przetwarzanie moich danych osobowych przez Łukasza
      Kozłowskiego, działającego pod firmą: Łukasz Kozłowski, ul. Odmy 4, 14-120
      Dąbrówno, www.motohistoria.pl, w celach niezbędnych do realizowania usługi
      udostępnienia Aplikacji Motohistoria. Oświadczam, że podane przeze mnie
      dane są zgodne z prawdą, podaję je dobrowolnie i wiem, że mam prawo
      żądania uzupełnienia, uaktualnienia, sprostowania danych osobowych,
      czasowego lub stałego wstrzymania ich przetwarzania oraz ich usunięcia,
      zgodnie z przepisami ustawy z dnia 10 maja 2018r. o ochronie danych
      osobowych oraz Rozporządzenia Parlamentu Europejskiego i Rady (UE)
      2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w
      związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
      takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
      ochronie danych). Opcjonalnie: Wyrażam zgodę na przetwarzanie moich danych
      osobowych przez Łukasza Kozłowskiego, działającego pod firmą: Łukasz
      Kozłowski ul. Odmy 4, 14-120 Dąbrówno, www.motohistoria.pl, dla celów
      marketingowych związanych z przesyłaniem drogą elektroniczną, listownie,
      telefonicznie przez Łukasza Kozłowskiego, informacji handlowych
      pochodzących od niego oraz podmiotów z nim współpracujących z branży
      motoryzacyjnej zgodnie z przepisami ustawy z dnia 10 maja 2018r. o
      ochronie danych osobowych, Rozporządzenia Parlamentu Europejskiego i Rady
      (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych
      w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
      przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
      rozporządzenie o ochronie danych) oraz pozostałymi właściwymi przepisami
      prawa powszechnie obowiązującego, w szczególności ustawy z dnia 16 lipca
      2004 roku Prawo telekomunikacyjne.
    </p>
  );
}
