import { useStore } from "../../utils/store";
import { ReactComponent as PowerSettingsNewIcon } from "../../svgs/logout.svg";
import { ReactComponent as Settings } from "../../svgs/settings.svg";
import { ReactComponent as Help } from "../../svgs/help.svg";
import { ReactComponent as Garage } from "../../svgs/garage.svg";
import { ReactComponent as User } from "../../svgs/account.svg";

import { default as s } from "./account-bottom-menu.module.scss";

export default function Account_BottomMenu({
  onLogout,
  onHandleTabNavigation,
  itemActive
}) {
  const [, dispatch] = useStore();

  return (
    <div className={s.bottomMenu}>
      <div className={s.user}>
        <User
          className={itemActive === "user" ? s.active : ""}
          onClick={() => onHandleTabNavigation("user")}
        />
      </div>
      <div className={s.help}>
        <Help
          className={itemActive === "help" ? s.active : ""}
          onClick={() => onHandleTabNavigation("help")}
        />
      </div>
      <div className={s.garage}>
        <Garage
          className={itemActive === "garage" ? s.active : ""}
          onClick={() => onHandleTabNavigation("garage")}
        />
      </div>
      <div className={s.settings}>
        <Settings
          className={itemActive === "settings" ? s.active : ""}
          onClick={() => onHandleTabNavigation("settings")}
        />
      </div>
      <div>
        <PowerSettingsNewIcon
          onClick={() => {
            dispatch({ type: "onLoading", payload: "loading" });
            onLogout();
          }}
        />
      </div>
    </div>
  );
}
