import "./car-info-modal.scss";

export default function CarInfoModal({
  vin,
  brand,
  model,
  gen,
  fuel,
  year,
  registration,
  ...props
}) {
  return (
    <div className="car-info-modal">
      <div className="car-info-modal-header">
        <span className="car-info-modal-header-title">
          Informacje o samochodzie
        </span>
        <span className="close" onClick={() => props.handleClose()}></span>
      </div>
      <div className="car-info-modal-content">
        <span className="car-info-modal-content-info">Marka: {brand}</span>
        <span className="car-info-modal-content-info">Model: {model}</span>
        <span className="car-info-modal-content-info">
          Generacja: {gen ? gen : "Brak informacji"}
        </span>
        <span className="car-info-modal-content-info">
          Rodzja paliwa: {fuel}
        </span>
        <span className="car-info-modal-content-info">Rocznik: {year}</span>
        <span className="car-info-modal-content-info">
          Numer rejestracyjny: {registration}
        </span>
        <span className="car-info-modal-content-info">VIN: {vin}</span>
      </div>
    </div>
  );
}
