const sanitizeStrings = value => {
  if (!value) return undefined;
  if (typeof value === "string") {
    return value
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  }
  return value;
};

export const UserPersonalDataModel = user => {
  const isAddress =
    user.city || user.postalCode || user.street || user.streetNo;
  return {
    firstname: sanitizeStrings(user.firstname),
    lastname: sanitizeStrings(user.lastname),
    county: user.county || undefined,
    phone: sanitizeStrings(user.phone),
    serviceName: sanitizeStrings(user.serviceName),
    specializations: user.specializations,
    bankAccount: user.bankAccount,
    tin: user.tin || undefined,
    address: isAddress
      ? {
          city: sanitizeStrings(user.city),
          postalCode: sanitizeStrings(user.postalCode),
          street: sanitizeStrings(user.street),
          streetNo: sanitizeStrings(user.streetNo)
        }
      : undefined
  };
};

export const UserFormDataModel = user => ({
  firstname: user.firstname || "",
  lastname: user.lastname || "",
  county: user.county || "",
  phone: user.phone || "",
  email: user.email || "",
  bankAccount: user.bankAccount || "",
  tin: user.tin || "",
  serviceName: user.serviceName || "",
  city: user.address.city || "",
  postalCode: user.address.postalCode || "",
  street: user.address.street || "",
  streetNo: user.address.streetNo || ""
});

export const UserCarModel = car => ({
  brand: car?.brand,
  model: car?.model,
  gen: car?.gen,
  year: car?.year,
  fuel: car?.fuel,
  registration: car?.registration,
  vin: car?.vin
});
