import React from "react";

import Rodo1 from "../../Regulations/Rodo1";
import Rodo2 from "../../Regulations/Rodo2";
import Regulamin from "../../Regulations/Regulamin";
import "./index.scss";

export default function AccountSettings() {
  // const [pushMessage, setPushMessage] = useState("send something to me");

  return (
    <div className="legal-terms-wrapper">
      {/* <button onClick={checkPushSubscription}>Allow Web Push</button> */}
      <Regulamin />
      <Rodo1 />
      <Rodo2 />
    </div>
  );
}
