const years = [];
const base_year = new Date().getFullYear();

function YearList(base) {
  for (let i = 0; i < 36; i++) {
    years.push((base - i).toString());
  }
  return years;
}

export default YearList(base_year);
