import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../../utils/store";
import { GetMonth, DayOfMonth, GetYear } from "../../../../utils/dateConverter";
import { getServicesByCar } from "../../../../requests/service-request";

import CarInfoModal from "./CarInfoModal";

import { ReactComponent as Approved } from "../../../../svgs/car-repair.svg";
import { ReactComponent as User } from "../../../../svgs/account.svg";
import { ReactComponent as Auto } from "../../../../svgs/garage.svg";
import { ReactComponent as Done } from "../../../../svgs/accept.svg";
import { ReactComponent as Back } from "../../../../svgs/back.svg";

export default function InShop({ onTabChange, onServiceItemChange }) {
  const [carToShowDetails, setCarToShowDetails] = useState(null);
  const [{ history }, dispatch] = useStore();
  const filtered = history.filter(
    (item) => item.shopApproved && !item.archived
  );
  const navigate = useNavigate();

  const handleEnterEditMode = (item) => {
    onTabChange("editing");
    onServiceItemChange(item);
  };

  const handleCarHistoryPreview = (carID) => {
    mutate(carID);
  };

  const { mutate } = useMutation(getServicesByCar, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: ({ data }) => {
      dispatch({ type: "updateHistory", payload: data });
      navigate("/account/garage/user-service-history");
    },
    onError: (error) => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  });

  return (
    <>
    { carToShowDetails !== null ?
      <CarInfoModal { ...carToShowDetails } handleClose={ () => setCarToShowDetails(null) } /> : null
    }
    <div className="service-history-list-container-new">
      {filtered.length ? (
        filtered.map((item, index) => (
          <div className="service-history-item-container-new" key={index}>
            <div className="service-history-item-status-bar border-top-radius">
              <span className="status-desc">
                {item.archived ? "gotowy" : "w serwisie"}
              </span>
            </div>
            <div className="service-history-item-wrap">
              <div className="service-history-item-timestamp">
                <span className="service-timestamp-date">
                  {DayOfMonth(item.createdAt)}
                </span>
                <span className="service-timestamp-month">
                  {GetMonth(item.createdAt)}
                </span>
                <span className="service-timestamp-year">
                  {GetYear(item.createdAt)}
                </span>
              </div>
              <div className="service-history-item">
                <div className="service-item-user-wrapper">
                  <User />
                  <span>{item.user.firstname.toUpperCase()}</span>
                  <span>{item.user.lastname.toUpperCase()}</span>
                </div>
                <div className="service-item-car-wrapper" onClick={() => setCarToShowDetails(item.car) } >
                  <Auto />
                  <span>{item.car.brand}</span>
                  <span>{item.car.model}</span>
                </div>
              </div>
            </div>
            <div
              className={`edit-mode-action-button-new ${
                item.archived ? "confirmed" : ""
              }`}
            >
              {item.archived ? (
                <Done />
              ) : (
                <div className="double-action">
                  <div onClick={() => handleCarHistoryPreview(item.car._id)}>
                    <Back />
                    <span>POPRZEDNIE NAPRAWY</span>
                  </div>
                  <div>
                    <Approved
                      onClick={() => {
                        handleEnterEditMode(item);
                      }}
                    />
                    <span>DOKONAJ NAPRAWY</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="service-history-no-results">
          <label>brak aut w warsztacie</label>
        </div>
      )}
    </div>
    </>
  );
}
