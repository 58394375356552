import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { validatePassword } from "../utils/validation";
import { useStore } from "../utils/store";
import styled from "styled-components";
import LockResetIcon from "@mui/icons-material/LockReset";

import { checkResetTokenRequest } from "../requests/password-reset";
import { confirmPasswordResetRequest } from "../requests/password-reset";

const ResetContainer = styled.div`
  z-index: 1;
  display: grid;
  justify-content: center;
  align-content: start;
  background-color: #f5f5f4;
  .reset-form {
    display: grid;
    height: 500px;
    place-content: center;
  }
  .nav-app-title {
    display: grid;
    grid-auto-flow: column;
    place-content: center;
    margin: 20px;
  }
  .nav-app-title span {
    font: 2em Russo One;
  }
  .nav-app-title span:nth-of-type(1) {
    color: var(--primary);
  }
  .reset-lock-icon {
    display: grid;
    justify-self: center;
    font-size: 4rem;
    color: rgba(0, 0, 0, 0.7);
  }
  input {
    width: 100vw;
    max-width: 400px;
    margin: 10px 0px;
    background-color: lightgrey;
    outline: none;
    border: none;
    font: 300 1rem var(--mainFontFamily);
    border-radius: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
  }
  button.reset-button {
    background-color: ${({ mismatch }) =>
      mismatch ? "var(--primaryHoverColor)" : "var(--primary)"};
    cursor: pointer;
    color: ${({ mismatch }) => (mismatch ? "salmon" : "white")};
    border: none;
    border-radius: 10px;
    font: 300 1.2rem var(--mainFontFamily);
    text-align: center;
    padding: 10px 0px;
    margin: 10px 0;
  }
  .form-validator-prompt {
    font: 600 0.8rem var(--mainFontFamily);
    margin: 0;
    color: #ff4154;
  }
`;

export default function Reset() {
  const [success, setSuccess] = useState(false);
  const [passwordValidationFailed, setPasswordValidationFailed] =
    useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [mismatch, setMismatch] = useState(false);
  const [userId, setUserId] = useState(null);
  const { token } = useParams();
  const navigate = useNavigate();
  const [, dispatch] = useStore();

  const { mutate: mutateCheckResetToken } = useMutation(
    checkResetTokenRequest,
    {
      retry: false,
      onSuccess: (data) => {
        setUserId(data.data);
        setTimeout(() => setSuccess(true), 1000);
      },
      onError: (error) => {
        setTimeout(() => {
          navigate("/response", { state: { error }, replace: true });
        }, 1000);
      },
    }
  );

  const { mutate: mutateConfirmResetPassword } = useMutation(
    confirmPasswordResetRequest,
    {
      retry: false,
      onSuccess: (res) => {
        dispatch({
          type: "onSuccess",
          payload: { visible: true, message: res.message },
        });
        setTimeout(() => {
          dispatch({ type: "onSuccess", payload: { visible: false } });
        }, 2000);
        navigate("/response", { replace: true });
      },
      onError: (error) => {
        dispatch({ type: "onError", payload: error });
        navigate("/response", { replace: true });
      },
    }
  );

  useEffect(() => {
    mutateCheckResetToken({ token });
    // eslint-disable-next-line
  }, []);

  const handleConfirmPasswordReset = () => {
    if (
      validatePassword(password) === false ||
      validatePassword(passwordConfirmation) === false
    ) {
      setPasswordValidationFailed(true);
    } else if (password === passwordConfirmation) {
      setPasswordValidationFailed(false);
      mutateConfirmResetPassword({
        password,
        passwordConfirmation,
        userId,
        token,
      });
    } else {
      setMismatch(true);
      setTimeout(() => setMismatch(false), 2000);
    }
  };

  return success ? (
    <ResetContainer mismatch={mismatch}>
      <div className="reset-form">
        <div className="nav-app-title">
          <span>resetuj</span>
          <span>Hasło</span>
        </div>
        <LockResetIcon className="reset-lock-icon" />
        <input
          placeholder="nowe hasło"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />

        {passwordValidationFailed && (
          <p className="form-validator-prompt">
            Hasło to minimum 8 znaków, w tym duża litera i cyfra
          </p>
        )}
        <input
          placeholder="powtórz hasło"
          type="password"
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        />
        {passwordValidationFailed && (
          <p className="form-validator-prompt">
            Hasło to minimum 8 znaków, w tym duża litera i cyfra
          </p>
        )}
        <button
          className="reset-button"
          onClick={() => password.length && handleConfirmPasswordReset()}
        >
          {mismatch ? "Hasła różnią się" : "Zmień"}
        </button>
      </div>
    </ResetContainer>
  ) : (
    <ResetContainer>"Loading..."</ResetContainer>
  );
}
