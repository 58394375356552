export const initialState = {
  user: undefined,
  isLoading: undefined,
  cars: [],
  success: {},
  history: [],
  shops: [],
  globalShop: {},
  reviews: [],
  appUsers: [],
  consentFormDisplay: false,
  acceptTerms: [false, false, false],
  termsAccepted: false
};
export default function Reducer(state, { payload, type }) {
  switch (type) {
    case "updateUser":
      return { ...state, user: payload };
    case "updateAppUsers":
      return { ...state, appUsers: payload };
    case "updateCars":
      return { ...state, cars: payload };
    case "updateHistory":
      return { ...state, history: payload };
    case "onLoading":
      return { ...state, isLoading: payload };
    case "onSuccess":
      return {
        ...state,
        success: { message: payload.message, visible: payload.visible }
      };
    case "pieMenuToggle":
      return { ...state, showPieMenu: payload };
    case "pickACarModalToggle":
      return { ...state, showPickACar: payload };
    case "updateShopList":
      return { ...state, shops: payload };
    case "setGlobalShop":
      return { ...state, globalShop: payload };
    case "updateReviews":
      return { ...state, reviews: payload };
    case "toggleConsentForm":
      return { ...state, consentFormDisplay: payload };
    case "acceptTerms":
      return { ...state, acceptTerms: payload };
    case "termsAccepted":
      if (payload.every(term => term === true)) {
        return { ...state, termsAccepted: true };
      } else return { ...state };
    case "onError":
      return { ...state, error: payload };
    case "onErrorReset":
      return { ...state, error: undefined };
    default:
      throw new Error("Reducer needs an action type");
  }
}
