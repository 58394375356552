const MONTHS_POLISH = [
  "styczeń",
  "luty",
  "marzec",
  "kwiecień",
  "maj",
  "czerwiec",
  "lipiec",
  "sierpień",
  "wrzesień",
  "październik",
  "listopad",
  "grudzień"
];

export const GetMonth = dateString => {
  return MONTHS_POLISH[new Date(dateString).getMonth()];
};

export const DayOfMonth = dateString => {
  return new Date(dateString).getDate();
};

export const GetYear = dateString => {
  return new Date(dateString).getFullYear();
};
