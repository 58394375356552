import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

export default function BasicRating({
  readonly = false,
  stars = null,
  size = "small"
}) {
  return (
    <Box
      className="rating-box"
      sx={{
        "& > legend": { mt: 2 }
      }}
    >
      <Rating
        name="simple-controlled"
        size={size}
        value={stars}
        precision={0.5}
        readOnly={readonly}
      />
    </Box>
  );
}
