export const logoutRequest = () => {
  return fetch(`/api/auth/logout`, {
    method: "POST",
    headers: { "Content-Type": "application/json" }
  }).then(async res => {
    if (!res.ok) {
      throw new Error((await res.json()).message);
    }
    return await res.json();
  });
};
