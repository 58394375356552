import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../../../utils/store";
import {
  addReview,
  updateReview
} from "../../../../../requests/review-request";

import RatingWithLabel from "./RatingWithLabel";
import "./index.scss";

export default function RatingModal({
  checkBoxState,
  oncheckBoxStateChange,
  serviceItem,
  onReviewSent
}) {
  const comment = useRef();
  const [{ history, user }, dispatch] = useStore();
  const [textAreaValue, setTextAreaValue] = useState(
    serviceItem?.review?.content || "Dodaj komentarz..."
  );
  const [reviewScore, setReviewScore] = useState(
    serviceItem?.review?.score || null
  );
  const navigate = useNavigate();

  useEffect(() => {
    setTextAreaValue(serviceItem?.review?.content || "Dodaj komentarz...");
    setReviewScore(serviceItem?.review?.score || null);
  }, [serviceItem]);

  const { mutate } = useMutation(addReview, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
      onReviewSent();
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: res => {
      const index = history.findIndex(item => item._id === res.data.serviceID);
      if (index !== -1) {
        history[index].review = res.data;
        dispatch({ type: "updateHistory", payload: history });
      }
    },
    onError: error => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    }
  });

  const { mutate: update } = useMutation(updateReview, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
      onReviewSent();
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: res => {
      const index = history.findIndex(item => item._id === res.data.serviceID);
      if (index !== -1) {
        history[index].review = res.data;
        dispatch({ type: "updateHistory", payload: history });
      }
    },
    onError: error => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    }
  });

  const handleModalClose = () => {
    oncheckBoxStateChange(false);
    setTimeout(() => {
      setReviewScore(serviceItem?.review?.score || 5);
      setTextAreaValue(serviceItem?.review?.content || "Dodaj komentarz...");
    }, 1200);
  };

  return (
    <div className="section full-height">
      <input
        className="modal-btn"
        type="checkbox"
        id="modal-btn"
        checked={checkBoxState}
        readOnly
        name="modal-btn"
      />
      <div className="modal">
        <div className="modal-wrap">
          <span className="modal-wrap-close-button" onClick={handleModalClose}>
            &times;
          </span>
          <p>Pomóż stworzyć bazę zaufanych mechaników oceniając warsztat:</p>
          {serviceItem?.shop ? (
            <p>{serviceItem.shop.serviceName?.toUpperCase()}</p>
          ) : null}
          <RatingWithLabel
            readonly={
              user.role === "ADMIN" ||
              user.role === "SERVICE" ||
              serviceItem?.review
                ? true
                : false
            }
            score={reviewScore}
            onSetScore={value => setReviewScore(value)}
            className="modal-rating-with-label"
          />
          <div className="modal-textarea-wrapper">
            <textarea
              name=""
              ref={comment}
              id=""
              cols="30"
              rows="10"
              onChange={e => setTextAreaValue(e.targetValue)}
              value={textAreaValue}
              disabled={
                user.role === "ADMIN" ||
                user.role === "SERVICE" ||
                serviceItem?.review
              }
            ></textarea>
            {user.role === "ADMIN" ||
            user.role === "SERVICE" ||
            serviceItem?.review ? (
              <button onClick={handleModalClose}>Cofnij</button>
            ) : (
              <button
                onClick={() =>
                  serviceItem?.review?.score
                    ? update({
                        reviewID: serviceItem.review._id,
                        score: reviewScore,
                        shopID: serviceItem.shop._id,
                        content: comment.current.value
                      })
                    : mutate({
                        authorID: user._id,
                        serviceID: serviceItem._id,
                        authorName: `${user.firstname} ${user.lastname}`,
                        shopID: serviceItem.shop._id,
                        score: reviewScore,
                        content: comment.current.value,
                        authorCar: `${serviceItem.car.brand} ${serviceItem.car.model}`
                      })
                }
              >
                wyślij
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
